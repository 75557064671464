import { useEffect, useState } from 'react';

export const useIsStuck = () => {
  const [container, setContainer] = useState<HTMLDivElement | null>(null);
  const [isStuck, setIsStuck] = useState(false);

  useEffect(() => {
    let observer: IntersectionObserver | undefined;

    if (container) {
      observer = new IntersectionObserver(
        ([entry]) => {
          const isStuck = entry.boundingClientRect.top < 1;
          setIsStuck(isStuck);
        },
        { threshold: [1] }
      );

      observer.observe(container);
    }

    return () => {
      if (observer && container) {
        observer.unobserve(container);
      }
    };
  }, [container]);

  return {
    isStuck,
    containerRef: setContainer,
  };
};
