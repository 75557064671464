import { useLocalStorage } from 'react-use';
import { useIntegrations } from '@/pages/Schedule/hooks/useIntegrations';
import { selectGoogleIntegrations } from '@/utils/googleCalendar';
import type { Destination } from '../types';

type Ret = {
  destination: Destination | null;
  setDestination: (destination: Destination | null) => void;
};

export const useCurrentDestination = (): Ret => {
  const { data: integrations = [] } = useIntegrations();
  const [savedDestination = null, setDestination] =
    useLocalStorage<Destination | null>('_ah_last_magic_destination', null);
  const suitableIntegrations = selectGoogleIntegrations(integrations);
  const isSavedDestinationSuitable = suitableIntegrations.some((item) => {
    if (item.id !== savedDestination?.integration.id) {
      return false;
    }
    return item.calendars.some((calendar) => {
      return (
        !calendar.readonly && calendar.uri === savedDestination.calendar.uri
      );
    });
  });
  const destination = isSavedDestinationSuitable ? savedDestination : null;

  return { destination, setDestination };
};
