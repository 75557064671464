import { type MutateOptions, useMutation } from '@tanstack/react-query';
import { gql } from 'graphql-request';
import { useCallback } from 'react';
import { gqlClient } from '@/lib';
import type { QueryError } from '@/utils/errors';
import type {
  SendQuickEntryMutation,
  SendQuickEntryMutationVariables,
} from './useSendQuickEntry.generated';

const query = gql`
  mutation SendQuickEntry($quickEntryId: ID!, $email: LowercaseString!) {
    sendQuickEntry(quickEntryId: $quickEntryId, email: $email)
  }
`;

export const useSendQuickEntry = () => {
  const { mutate, ...rest } = useMutation<
    SendQuickEntryMutation,
    QueryError,
    SendQuickEntryMutationVariables
  >({
    mutationFn: (variables) => gqlClient.request(query, variables),
  });

  const sendQuickEntry = useCallback(
    (
      quickEntryId: string,
      email: string,
      options?: MutateOptions<
        SendQuickEntryMutation,
        QueryError,
        SendQuickEntryMutationVariables
      >
    ) => {
      return mutate({ quickEntryId, email }, options);
    },
    [mutate]
  );

  return { sendQuickEntry, ...rest };
};
