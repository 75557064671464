import { VscCircleFilled } from 'react-icons/vsc';
import { AppLoader } from '@/components/AppLoader';
import type { DecoratedIntegration } from '@/pages/Schedule/hooks/useIntegrations';
import type { Calendar } from '@/types/gql.generated';
import {
  Box,
  Text,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Button,
  Flex,
  Icon,
} from '@/ui';
import { usePickerIntegrations } from '../hooks/usePickerIntegrations';
import { IntegrationErrors } from './IntegrationErrors';

type Props = {
  isOpen: boolean;
  onSelect: (integration: DecoratedIntegration, calendar: Calendar) => void;
  onClose: () => void;
};

export const MultiExportModal = ({ isOpen, onSelect, onClose }: Props) => {
  const { integrations, isLoading } = usePickerIntegrations();

  return (
    <Modal
      isCentered
      isOpen={isOpen}
      size={{ base: 'full', md: 'lg' }}
      onClose={onClose}
    >
      <ModalOverlay />
      <ModalContent
        borderRadius="2xl"
        layerStyle="magicBackgroundSecondary"
        maxH="calc(100vh - var(--chakra-space-12))"
        overflow="auto"
        py="6"
      >
        <ModalCloseButton />
        <ModalBody flexDir="column" textAlign="center">
          <Text fontSize="72px" mb="2">
            📆
          </Text>
          <Text fontSize="32px" fontWeight="bold" lineHeight="1.2">
            Where should we
            <br />
            add the events?
          </Text>

          {!isLoading && <IntegrationErrors mx="auto" my="8" />}

          {integrations.length > 0 && (
            <Flex
              bg="white"
              borderRadius="lg"
              direction="column"
              my="5"
              p="2"
              textAlign="left"
              w="100%"
            >
              {isLoading && <AppLoader my="8" />}

              {integrations.map(({ integration, suitableCalendars }) => (
                <Box key={integration.id}>
                  <Text color="customgray.mid" fontSize="sm" px="3" py="2">
                    {integration.name}
                  </Text>

                  <Flex direction="column">
                    {suitableCalendars.map((calendar) => (
                      <Button
                        colorScheme="dark"
                        h="8"
                        isDisabled={calendar.readonly}
                        justifyContent="flex-start"
                        key={calendar.uri}
                        px="2"
                        variant="ghost"
                        w="100%"
                        leftIcon={
                          <Icon
                            as={VscCircleFilled}
                            boxSize="20px"
                            color={calendar.color}
                          />
                        }
                        onClick={() => onSelect(integration, calendar)}
                      >
                        {calendar.name}
                      </Button>
                    ))}
                  </Flex>
                </Box>
              ))}
            </Flex>
          )}

          <Button
            colorScheme="dark"
            fontSize="md"
            textDecor="underline"
            variant="link"
            onClick={onClose}
          >
            Not now
          </Button>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
