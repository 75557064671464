import {
  Text,
  Button,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
  PopoverCloseButton,
  Icon,
} from '@/ui';
import { CopyLinkInput } from './CopyLinkInput';
import ShareIcon from './icons/share.svg?react';
import { SocialShareButtons } from './SocialShareButtons';

export const SocialSharePopover = () => {
  const url = window.location.toString();

  return (
    <Popover
      isLazy
      modifiers={[
        {
          name: 'preventOverflow',
          options: {
            padding: 'var(--chakra-space-4)',
          },
        },
      ]}
    >
      <PopoverTrigger>
        <Button
          bg="white"
          fontSize="md"
          leftIcon={<Icon as={ShareIcon} boxSize="20px" />}
          variant="secondary"
        >
          Share
        </Button>
      </PopoverTrigger>
      <PopoverContent
        borderRadius="xl"
        minW={{ md: '370px' }}
        shadow="0px 0px 14px 0px rgba(0, 0, 0, 0.25)"
      >
        <PopoverHeader
          alignItems="center"
          display="flex"
          fontSize="xs"
          fontWeight="bold"
          justifyContent="space-between"
          letterSpacing="0.72px"
          px="6"
          py="3"
          textTransform="uppercase"
        >
          <Text>
            Share{' '}
            <Text as="span" textStyle="magic">
              Magic
            </Text>
          </Text>
          <PopoverCloseButton pos="static" />
        </PopoverHeader>
        <PopoverBody px="6" py="3">
          <Text fontWeight="bold" mb="1.5">
            Share via
          </Text>
          <SocialShareButtons url={url} />

          <Text fontWeight="bold" mb="1.5" mt="6">
            or copy link
          </Text>
          <CopyLinkInput url={url} />
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};
