import { GoogleCalendarAuthButton } from '@/components/auth';
import { Benefits } from '@/components/SettingsModal/tabs/Calendars/components/Benefits';
import { useQuickCreateContext } from '@/pages/QuickCreate/contexts';
import { getRedirectQuery } from '@/pages/QuickCreate/utils/googleCalendar';
import { PlatformTypes } from '@/types/gql.generated';
import { Button, Heading, Text } from '@/ui';
import { Card, CardBody } from '../Card';

type Props = {
  onComplete: () => void;
};

export const GoogleIntegrationBody = ({ onComplete }: Props) => {
  const { job } = useQuickCreateContext();

  return (
    <>
      <Text fontSize="72px" mb="6" textAlign="center">
        ✅
      </Text>
      <Heading
        as="h2"
        fontSize={{ base: '2xl', sm: '4xl' }}
        fontWeight="bold"
        mb="10"
        textAlign="center"
      >
        Done! Your preferences are saved.
      </Heading>
      <Card mb="8" shadow="none">
        <CardBody flexDirection="column">
          <Benefits mb="6" />

          <GoogleCalendarAuthButton
            launchOnRedirect="set-preferred-platform"
            quickCreateJobId={job?.id}
            redirectParams={getRedirectQuery(job, {
              platform: PlatformTypes.GoogleIntegration,
            })}
          />
        </CardBody>
      </Card>

      <Button colorScheme="dark" variant="link" onClick={onComplete}>
        Maybe later
      </Button>
    </>
  );
};
