import { useRef } from 'react';
import { useLocalStorage } from 'react-use';
import { useCurrentUserContext } from '@/contexts';
import { useDisclosure, useOutsideClick } from '@/ui';

const STORAGE_KEY = '_ah_platform_menu_open_count';

const SHOW_COUNT = 3;

export const usePlatformPinTooltip = () => {
  const { isOpen, onClose: close, onOpen: open } = useDisclosure();
  const tooltipRef = useRef<HTMLDivElement | null>(null);
  const [menuOpenCount = 0, setMenuOpenCount] = useLocalStorage(STORAGE_KEY, 0);
  const { currentUser } = useCurrentUserContext();
  const hasPlatform = Boolean(currentUser?.preferredPlatform);

  useOutsideClick({
    ref: tooltipRef,
    handler: close,
  });

  const dismiss = () => {
    setMenuOpenCount(SHOW_COUNT + 1);
    close();
  };

  const incrementMenuOpenCount = () => setMenuOpenCount(menuOpenCount + 1);
  const shouldOpen = menuOpenCount === SHOW_COUNT && !hasPlatform;

  return {
    tooltipRef,
    dismiss,
    incrementMenuOpenCount,
    shouldOpen,
    isOpen,
    open,
    close,
  };
};
