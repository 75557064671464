import { Flex, type FlexProps } from '@/ui';
import { Footer } from '../Footer';

export const Layout = ({ children, ...props }: FlexProps) => {
  return (
    <Flex direction="column" flex="1" {...props}>
      {children}
      <Footer />
    </Flex>
  );
};
