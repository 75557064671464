import { useMemo } from 'react';
import type { PlatformExport } from '@/pages/QuickCreate/hooks/usePlatformExports';
import { Box, Button, Flex, Link, Text } from '@/ui';
import { usePickerIntegrations } from '../hooks/usePickerIntegrations';

type Props = {
  platformExport: PlatformExport;
  onResetClick: () => void;
};

export const SuccessState = ({ platformExport, onResetClick }: Props) => {
  const { integrations } = usePickerIntegrations();

  const exportCalendarName = useMemo(() => {
    const calendars = integrations.flatMap(
      (integration) => integration.suitableCalendars
    );
    const exportCalendarId = platformExport.calendarId;
    const exportCalendar = calendars.find(
      (calendar) => calendar.id === exportCalendarId
    );
    return exportCalendar?.name ?? exportCalendarId;
  }, [platformExport, integrations]);

  return (
    <Box>
      <Flex
        bg="#EDE1ED"
        borderRadius="lg"
        direction={{ base: 'column', sm: 'row' }}
        gap={{ base: 2, sm: 4 }}
        px="4"
        py="3"
      >
        <Text flex="1" wordBreak="break-word">
          🎉 Added {platformExport.entryExports.length} event
          {platformExport.entryExports.length !== 1 && 's'} to{' '}
          <Text as="span" fontWeight="bold">
            {exportCalendarName}
          </Text>
          .
        </Text>
        <Link
          alignSelf={{ sm: 'center' }}
          colorScheme="dark"
          fontWeight="medium"
          href={platformExport.entryExports[0]?.externalLink}
          target="_blank"
          textDecor="underline"
          variant="link"
        >
          Go to calendar
        </Link>
      </Flex>
      <Button
        colorScheme="dark"
        fontSize="md"
        mt="2"
        textDecor="underline"
        variant="link"
        onClick={onResetClick}
      >
        Add to another calendar
      </Button>
    </Box>
  );
};
