import type { PlatformTypes } from '@/types/gql.generated';
import { Highlight } from '@/ui';
import { getName } from '@/utils/platformLib';

type Props = {
  platform: PlatformTypes;
  text: string;
};

export const HighlightPlatform = ({ platform, text }: Props) => {
  const platformName = getName(platform);

  return (
    <Highlight
      query={platformName}
      styles={{ bg: '#FBEAFF', borderRadius: 'md', pb: '1', px: '1' }}
    >
      {text.replace(/{platform}/g, platformName)}
    </Highlight>
  );
};
