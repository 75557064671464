import { LinkIcon } from '@chakra-ui/icons';
import { AnimatePresence } from 'framer-motion';
import { useEffect, useState } from 'react';
import { config } from '@/config';
import { useCurrentUserContext } from '@/contexts/CurrentUserContext';
import { useAnalytics } from '@/hooks/useAnalytics';
import { useQuickCreateContext } from '@/pages/QuickCreate/contexts';
import { MotionFlex, IconButton, Text } from '@/ui';
import { useAuthModal } from '../../AuthModal';

export const ShareButton = () => {
  const { isAuthenticated } = useCurrentUserContext();
  const { job } = useQuickCreateContext();
  const { open: openAuthModal } = useAuthModal();
  const { trackEvent } = useAnalytics();
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    if (copied) {
      const timer = setTimeout(() => {
        setCopied(false);
      }, 2000);

      return () => {
        clearTimeout(timer);
      };
    }
  }, [copied, setCopied]);

  const handleCopy = async () => {
    if (!isAuthenticated) {
      openAuthModal({ reason: 'create-share-link' });
    } else if (job) {
      const url = `${config.basePath}/magic/s/${job.id}`;
      await navigator.clipboard.writeText(url);
      setCopied(true);
      trackEvent('quick-create:click copy item link');
    }
  };

  return (
    <AnimatePresence mode="popLayout">
      <MotionFlex
        align="center"
        animate={{ opacity: 1 }}
        exit={{ opacity: 0 }}
        h="10"
        initial={{ opacity: 0 }}
        justify="center"
        key={copied ? 'success' : 'default'}
        transition={{ duration: 0.1 }}
      >
        {copied ? (
          <Text color="green" fontWeight="bold">
            Copied!
          </Text>
        ) : (
          <IconButton
            aria-label="Share results"
            bg="white"
            border="1px solid rgba(0, 0, 0, 0.15)"
            icon={<LinkIcon boxSize="4" />}
            variant="secondary"
            onClick={handleCopy}
          />
        )}
      </MotionFlex>
    </AnimatePresence>
  );
};
