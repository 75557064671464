import { DateTime } from 'luxon';
import {
  type KeyboardEvent,
  type ChangeEvent,
  createRef,
  useEffect,
  useState,
} from 'react';
import type ReactDatePicker from 'react-datepicker';
import { DatePicker } from '@/pages/Schedule/components';
import { Checkbox, Flex, Input, Text, forwardRef } from '@/ui';
import { maybeAdjustDefaultYear } from '@/utils/dates';

type Props = {
  hasRecurrence: boolean;
  isOnDay: boolean;
  startDate: DateTime;
  endDate: DateTime;
  untilDate: DateTime | null;
  onStartChange: (startDate: DateTime) => void;
  onEndChange: (endDate: DateTime) => void;
  onUntilChange: (untilDate: DateTime | null) => void;
};

const CalendarInput = forwardRef((props, ref) => (
  <Input
    _hover={{ borderColor: 'gray.300' }}
    borderColor="gray.100"
    borderRadius="md"
    ref={ref}
    size="sm"
    textAlign="center"
    variant="primary"
    w="125px"
    {...props}
  />
));

export const DateRangeInput = ({
  hasRecurrence,
  isOnDay,
  startDate,
  endDate,
  untilDate,
  onStartChange,
  onEndChange,
  onUntilChange,
}: Props) => {
  const startDatePickerRef = createRef<ReactDatePicker>();
  const endDatePickerRef = createRef<ReactDatePicker>();
  const [isMouseOverCalendar, setIsMouseOverCalendar] = useState(false);
  const adjustedEndDate = endDate.minus({ days: isOnDay ? 1 : 0 });

  const [indefinitely, setIndefinitely] = useState(hasRecurrence && !untilDate);
  useEffect(() => {
    setIndefinitely(hasRecurrence && !untilDate);
  }, [hasRecurrence, untilDate]);

  // The selected end date is either the endDate (non-recurring) or untilDate
  // (recurring with indefinitely deselected) or undefined (recurring with
  // indefinitely selected).
  let selectedEndDate: DateTime | null = null;
  if (hasRecurrence && !indefinitely) {
    selectedEndDate = untilDate;
  } else if (!hasRecurrence) {
    selectedEndDate = adjustedEndDate;
  }

  const handleStartChange = (date: Date | null) => {
    if (!date) return;
    const newStartDate = maybeAdjustDefaultYear(DateTime.fromJSDate(date));
    onStartChange(newStartDate);
  };

  const handleEndChange = (date: Date | null) => {
    if (!date) return;
    const newEndDate = maybeAdjustDefaultYear(
      DateTime.fromJSDate(date).plus({
        days: isOnDay ? 1 : 0,
      })
    );
    onEndChange(newEndDate);
  };

  const handleUntilChange = (date: Date | null) => {
    setIndefinitely(false);
    onUntilChange(date ? DateTime.fromJSDate(date).endOf('day') : null);
  };

  const handleIndefinitelyChange = (event: ChangeEvent<HTMLInputElement>) => {
    const checked = event.currentTarget.checked;
    setIndefinitely(checked);
    if (checked) {
      onUntilChange(null);
      endDatePickerRef.current?.setOpen(false);
    }
  };

  // Move focus to the next input on tab instead of entering the calendar
  //github.com/Hacker0x01/react-datepicker/issues/2058#issuecomment-589451843
  const handleStartKeyDown = (event: KeyboardEvent<HTMLElement>) => {
    if (event.key === 'Tab') {
      startDatePickerRef.current?.setOpen(false);
    }
  };
  const handleEndKeyDown = (event: KeyboardEvent<HTMLElement>) => {
    if (event.key === 'Tab') {
      endDatePickerRef.current?.setOpen(false);
    }
  };

  return (
    <Flex align="center" gap="2">
      <DatePicker
        dateFormat="MMM d, yyyy"
        enableTabLoop={false}
        ref={startDatePickerRef}
        selected={startDate}
        selectsStart={hasRecurrence}
        customInput={
          <CalendarInput aria-label="Start date" onChange={handleStartChange} />
        }
        onChange={handleStartChange}
        onKeyDown={handleStartKeyDown}
      />

      <Text color="customgray.alsolight">to</Text>

      <DatePicker
        dateFormat="MMM d, yyyy"
        enableTabLoop={false}
        endDate={hasRecurrence ? untilDate : adjustedEndDate}
        minDate={startDate}
        ref={endDatePickerRef}
        selected={selectedEndDate}
        selectsEnd
        value={hasRecurrence && !untilDate ? 'Indefinitely' : undefined}
        customInput={
          <CalendarInput
            aria-label={hasRecurrence ? 'Until date' : 'End date'}
            onBlur={() => endDatePickerRef.current?.setOpen(false)}
            onChange={hasRecurrence ? handleUntilChange : handleEndChange}
          />
        }
        startDate={
          !hasRecurrence || untilDate || isMouseOverCalendar
            ? startDate
            : undefined
        }
        onChange={hasRecurrence ? handleUntilChange : handleEndChange}
        onDayMouseEnter={() => setIsMouseOverCalendar(true)}
        onKeyDown={handleEndKeyDown}
        onMonthMouseLeave={() => setIsMouseOverCalendar(false)}
      >
        {hasRecurrence && (
          <Checkbox
            color="customgray.mid"
            isChecked={indefinitely}
            onChange={handleIndefinitelyChange}
          >
            Indefinitely
          </Checkbox>
        )}
      </DatePicker>
    </Flex>
  );
};
