import { Box, type BoxProps, forwardRef } from '@/ui';

type Props = BoxProps & {
  isOpen: boolean;
};

export const DateTimeTriggerButton = forwardRef(
  ({ isOpen, ...props }: Props, ref) => {
    return (
      <Box
        _hover={{ bg: 'gray.100' }}
        as="button"
        bg={isOpen ? 'gray.100' : undefined}
        borderRadius="md"
        fontWeight="medium"
        px="2"
        py="1"
        ref={ref}
        textAlign="left"
        w="100%"
        sx={{
          ':first-letter': {
            textTransform: 'capitalize',
          },
        }}
        {...props}
      />
    );
  }
);
