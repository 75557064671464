import { Flex, Link, Text } from '@/ui';

type Props = Partial<{
  label: string;
  cta: string;
  href: string;
}>;

export const SuccessStat = ({ label, cta, href }: Props) => {
  return (
    <Flex
      direction="column"
      gap="1"
      justify="center"
      lineHeight="1"
      minW="125px"
      textAlign="center"
    >
      {label && (
        <Text fontSize="sm" fontWeight="medium">
          {label}
        </Text>
      )}
      {href && (
        <Link
          colorScheme="dark"
          fontSize="sm"
          href={href}
          target="_blank"
          textDecor="underline"
        >
          {cta}
        </Link>
      )}
    </Flex>
  );
};
