import { useRef } from 'react';
import { useOutsideClick } from '@/ui';

export const useClosePopoverOnOutsideClick = (closeFn: () => void) => {
  const popoverRef = useRef<HTMLDivElement | null>(null);
  const triggerRef = useRef<HTMLButtonElement | null>(null);

  useOutsideClick({
    ref: popoverRef,
    handler: (event) => {
      const target = event.target as HTMLElement;

      const isTriggerButton =
        target === triggerRef.current || triggerRef.current?.contains(target);

      const isClickInside =
        target === popoverRef.current || popoverRef.current?.contains(target);

      const shouldClosePopover = !isTriggerButton && !isClickInside;

      if (shouldClosePopover) {
        closeFn();
      }
    },
  });

  return {
    popoverRef,
    triggerRef,
  };
};
