import { useAnalytics } from '@/hooks/useAnalytics';
import {
  Flex,
  forwardRef,
  Icon,
  IconButton,
  type IconButtonProps,
  Link,
} from '@/ui';
import Facebook from './icons/facebook.svg?react';
import LinkedIn from './icons/linkedin.svg?react';
import X from './icons/x.svg?react';

const SocialIconButton = forwardRef((props: IconButtonProps, ref) => (
  <IconButton
    as={Link}
    boxSize="40px"
    ref={ref}
    target="_blank"
    variant="secondary"
    {...props}
  />
));

type Props = {
  url: string;
};

export const SocialShareButtons = ({ url }: Props) => {
  const encodedUrl = encodeURIComponent(url);
  const { trackEvent } = useAnalytics();

  return (
    <Flex gap="2">
      <SocialIconButton
        aria-label="Facebook"
        href={`https://www.facebook.com/sharer/sharer.php?u=${encodedUrl}`}
        icon={<Icon as={Facebook} boxSize="22px" />}
        onClick={() =>
          trackEvent('quick-create:click share', { channel: 'facebook' })
        }
      />
      <SocialIconButton
        aria-label="LinkedIn"
        href={`https://www.linkedin.com/shareArticle?mini=true&url=${encodedUrl}`}
        icon={<Icon as={LinkedIn} boxSize="22px" />}
        onClick={() =>
          trackEvent('quick-create:click share', { channel: 'linkedin' })
        }
      />
      <SocialIconButton
        aria-label="X"
        href={`https://twitter.com/intent/tweet?url=${encodedUrl}`}
        icon={<Icon as={X} boxSize="22px" />}
        onClick={() => trackEvent('quick-create:click share', { channel: 'x' })}
      />
    </Flex>
  );
};
