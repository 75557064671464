import type { MouseEventHandler } from 'react';
import { useAnalytics } from '@/hooks/useAnalytics';
import type { LaunchOption } from '@/hooks/useLauncher';
import type { DecoratedQuickCreateEntryJob } from '@/pages/QuickCreate/types';
import { PlatformTypes } from '@/types/gql.generated';
import { Button, type ButtonProps, Flex, Icon, forwardRef } from '@/ui';
import { useIsExtension } from '../../hooks/useIsExtension';
import { useAuthModal } from '../AuthModal';
import GoogleCalendarIcon from './icon.svg?react';

type Props = ButtonProps & {
  isAuth?: boolean;
  job?: DecoratedQuickCreateEntryJob | null;
  launch?: LaunchOption;
  redirectParams?: Record<string, string>;
};

const ButtonWrapper = forwardRef<ButtonProps, typeof Button>((props, ref) => {
  const { isExtension } = useIsExtension();

  return (
    <Button
      colorScheme="dark"
      fontSize={isExtension ? 'sm' : 'md'}
      fontWeight="bold"
      ref={ref}
      shadow="0px 0px 8px 2px rgba(0, 0, 0, 0.10)"
      {...props}
    />
  );
});

export const GoogleCalendarButton = forwardRef<Props, 'button'>(
  ({ children, isAuth, launch, as, onClick, ...buttonProps }, ref) => {
    const { trackEvent } = useAnalytics();
    const { open: openAuthModal } = useAuthModal();

    const wrapClick = (
      callback?: MouseEventHandler<HTMLElement>
    ): MouseEventHandler<HTMLElement> => {
      return (event) => {
        trackEvent('quick-create:click add-many', {
          platform: PlatformTypes.GoogleIntegration,
          path: window.location.pathname,
        });
        callback?.(event);
      };
    };

    const content = (
      <Flex align="center" gap="2" left={-1} pos="relative">
        <Icon as={GoogleCalendarIcon} boxSize={{ base: '16px', md: '18px' }} />
        {children}
      </Flex>
    );

    if (isAuth) {
      return (
        <ButtonWrapper
          type="button"
          {...buttonProps}
          onClick={wrapClick(() => {
            openAuthModal({
              reason: 'google-calendar-integration-multi',
              launch,
            });
          })}
        >
          {content}
        </ButtonWrapper>
      );
    }

    return (
      <ButtonWrapper
        as={as}
        ref={ref}
        onClick={wrapClick(onClick)}
        {...buttonProps}
      >
        {content}
      </ButtonWrapper>
    );
  }
);
