import { useSetPreferredPlatform } from '@/hooks/useSetPreferredPlatform';
import { isPinnable, isPlatformType } from '@/utils/platformLib';
import { usePinChangeTip } from '../../hooks/usePinChangeTip';

type Options = {
  platform?: string | undefined;
  onComplete?: () => void;
};

export const useHandleAuthenticate = () => {
  const { setPreferredPlatform } = useSetPreferredPlatform();
  const { trigger: triggerPinChangeTip } = usePinChangeTip();

  const handleAuthenticate = ({ platform, onComplete }: Options) => {
    if (isPlatformType(platform) && isPinnable(platform)) {
      setPreferredPlatform(platform, {
        onSuccess: () => triggerPinChangeTip(null, platform),
        onSettled: () => onComplete?.(),
      });
    } else {
      onComplete?.();
    }
  };

  return { handleAuthenticate };
};
