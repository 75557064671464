import { DateTime } from 'luxon';
import { useMemo } from 'react';
import { type Options, RRule, type Weekday } from 'rrule';
import { TIME_ZONE_UTC } from '@/constants';
import type { DecoratedQuickCreateEntry } from '@/pages/QuickCreate/types';
import type { RecurrenceOption } from '@/pages/Schedule/components';
import {
  getUntilDateForAllDayEntry,
  getUntilDateStringForAllDayEntry,
  getUntilDateTimeForTimedEntry,
} from '@/pages/Schedule/hooks/useUpdateEntryHandlers';
import { getDefaultWeekday, parseRRule } from '@/pages/Schedule/utils';
import {
  applyTimeToDate,
  areDaysEqual,
  getLocalTimeZone,
  isMidnight,
} from '@/utils/dates';
import type { QuickEntryChangeHandler } from '../../../types';

export const useDateTimeHandlers = (
  entry: DecoratedQuickCreateEntry,
  onChange: QuickEntryChangeHandler
) => {
  const { startDate, endDate, isOnDay, rule, timeZone } = entry;
  const { frequency, untilDate, byWeekday, interval } = useMemo(
    () =>
      parseRRule({
        rule,
        isOnDay,
        startDate,
        scheduleTimeZone: timeZone,
        entryTimeZone: timeZone,
      }),
    [rule, isOnDay, startDate, timeZone]
  );

  const createRecurrenceRule = (
    rruleOptions: Partial<Options>,
    entryOptions: Pick<DecoratedQuickCreateEntry, 'isOnDay'> = {
      isOnDay,
    }
  ): string => {
    const newRRule = new RRule({
      ...RRule.parseString(rule ?? ''),
      ...rruleOptions,
    });

    let ruleStr = newRRule.toString();
    if (ruleStr && entryOptions.isOnDay) {
      ruleStr = getUntilDateStringForAllDayEntry(ruleStr);
    }

    return ruleStr;
  };

  const onStartDateChange = (selectedStartDate: DateTime) => {
    const newStartDateTime = applyTimeToDate(selectedStartDate, startDate);

    if (frequency === null) {
      const duration = endDate.diff(startDate, 'minutes').minutes;
      const newEndDateTime = newStartDateTime.plus({ minutes: duration });

      onChange({
        startDate: newStartDateTime,
        endDate: newEndDateTime,
      });
    } else {
      let nextRule = rule;

      if (untilDate) {
        const duration = untilDate.diff(startDate, 'minutes').minutes;
        const newUntilDate = newStartDateTime.plus({ minutes: duration });
        nextRule = createRecurrenceRule({
          until: newUntilDate.toJSDate(),
        });
      }

      const newEndDateTime = isOnDay
        ? newStartDateTime.startOf('day').plus({ days: 1 })
        : applyTimeToDate(newStartDateTime, endDate);

      onChange({
        rule: nextRule,
        startDate: newStartDateTime,
        endDate: newEndDateTime,
      });
    }
  };

  const onEndDateChange = (selectedEndDate: DateTime) => {
    const newEndDateTime = applyTimeToDate(selectedEndDate, endDate);
    onChange({ endDate: newEndDateTime });
  };

  const onUntilDateChange = (selectedUntilDate: DateTime | null) => {
    if (selectedUntilDate) {
      const newUntilDate = isOnDay
        ? getUntilDateForAllDayEntry(selectedUntilDate)
        : selectedUntilDate;

      const rule = createRecurrenceRule({
        until: newUntilDate.toJSDate(),
      });

      onChange({ rule });
    } else {
      onChange({ rule: null });
    }
  };

  const onRecurrenceChange = (option: RecurrenceOption) => {
    if (option.rule.freq === null) {
      onChange({ rule: null });
      return;
    }

    const rule = createRecurrenceRule({
      freq: option.rule.freq,
      interval: option.rule.interval,
      byweekday: [],
    });

    let newEndDateTime = applyTimeToDate(startDate, endDate);
    if (isMidnight(newEndDateTime) || isOnDay) {
      newEndDateTime = newEndDateTime.plus({ days: 1 });
    }

    onChange({
      rule,
      endDate: newEndDateTime,
    });
  };

  const onMonthChange = (weekday: Weekday | null) => {
    const rule = createRecurrenceRule({
      byweekday: weekday === null ? null : [weekday],
    });
    onChange({ rule });
  };

  const onWeekdayChange = (weekday: Weekday[]) => {
    const rule = createRecurrenceRule({
      byweekday: weekday.length ? weekday : [getDefaultWeekday(startDate)],
    });
    onChange({ rule });
  };

  const onAddTime = () => {
    const hour = DateTime.local().hour;
    const newStartDate = startDate.set({ hour });
    const adjustedEndDate = endDate.minus({
      days: isOnDay ? 1 : 0,
    });
    const newEndDate = newStartDate.set({
      day: adjustedEndDate.day,
      month: adjustedEndDate.month,
      year: adjustedEndDate.year,
      hour: hour + 1,
    });

    const newTimeZone = getLocalTimeZone();

    const rule = createRecurrenceRule(
      {
        until: untilDate
          ? getUntilDateTimeForTimedEntry(untilDate, newTimeZone).toJSDate()
          : null,
      },
      { isOnDay: false }
    );

    onChange({
      timeZone: newTimeZone,
      startDate: newStartDate,
      endDate: newEndDate,
      isOnDay: false,
      rule,
    });
  };

  const onRemoveTime = () => {
    if (!startDate || !endDate) {
      return;
    }

    const newStartDate = startDate.startOf('day');
    const newEndDate = endDate.startOf('day').plus({ days: 1 });

    const rule = createRecurrenceRule(
      {
        until: untilDate
          ? getUntilDateForAllDayEntry(untilDate).toJSDate()
          : null,
      },
      { isOnDay: true }
    );

    onChange({
      timeZone: TIME_ZONE_UTC,
      startDate: newStartDate,
      endDate: newEndDate,
      isOnDay: true,
      rule,
    });
  };

  const onStartTimeChange = (updatedStartDateTime: DateTime) => {
    const duration = endDate.diff(startDate, 'minutes').minutes;
    const newStartDate = applyTimeToDate(startDate, updatedStartDateTime);
    const newEndDate = newStartDate.plus({ minutes: duration });
    onChange({ startDate: newStartDate, endDate: newEndDate });
  };

  const onEndTimeChange = (updatedEndDateTime: DateTime) => {
    let newEndDate = applyTimeToDate(endDate, updatedEndDateTime);

    // the end date is always relative to start date if recurrence is used
    if (frequency !== null) {
      newEndDate = applyTimeToDate(startDate, updatedEndDateTime);
    }

    // Don't allow times earlier than the start time
    if (newEndDate < startDate) {
      newEndDate = startDate.plus({ hours: 1 });
    }

    // For midnight end times, ensure the date is the start of tomorrow
    // instead of midnight on the same date
    if (areDaysEqual(startDate, newEndDate) && isMidnight(newEndDate)) {
      newEndDate = newEndDate.plus({ days: 1 });
    }

    onChange({ endDate: newEndDate });
  };

  return {
    startDate,
    endDate,
    untilDate,
    isOnDay,
    rule,
    frequency,
    byWeekday,
    interval,
    onStartDateChange,
    onEndDateChange,
    onUntilDateChange,
    onRecurrenceChange,
    onMonthChange,
    onWeekdayChange,
    onAddTime,
    onRemoveTime,
    onStartTimeChange,
    onEndTimeChange,
  };
};
