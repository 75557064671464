import { forwardRef, type ButtonProps, Button } from '@chakra-ui/react';
import { darken } from 'color2k';

export const ErrorButton = forwardRef<ButtonProps, 'button'>((props, ref) => {
  return (
    <Button
      _hover={{ bg: darken('#716ADD', 0.1) }}
      bg="#716ADD"
      color="white"
      mt="2"
      size="sm"
      {...props}
      ref={ref}
    />
  );
});
