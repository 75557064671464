import type { ComponentProps } from 'react';
import { MotionFlex, type MotionFlexProps } from '@/ui';
import { forwardRef } from '@/ui';
import { useQuickCreateContext } from '../contexts';
import { EditEntryCard } from './EditEntryCard';
import { MultiExportCard } from './GoogleCalendar';

type Props = MotionFlexProps & {
  multiExportCardProps?: ComponentProps<typeof MultiExportCard>;
};

export const EntryList = forwardRef(
  ({ multiExportCardProps, ...props }: Props, ref) => {
    const { job } = useQuickCreateContext();

    return (
      <MotionFlex flexDir="column" gap="4" ref={ref} w="100%" {...props}>
        <MultiExportCard {...multiExportCardProps} />

        {job?.entries.map((entry, index) => (
          <EditEntryCard entry={entry} isFirst={index === 0} key={entry.id} />
        ))}
      </MotionFlex>
    );
  }
);
