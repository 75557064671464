import type { ReactNode } from 'react';
import { useFeatureFlag } from '@/hooks/useFeatureFlag';
import { Text } from '@/ui';
import { CenteredContent } from './CenteredContent';

type Props = {
  children: ReactNode;
};

export const MaintenanceMode = ({ children }: Props) => {
  const { value: quickCreateMaintenanceModeEnabled } = useFeatureFlag(
    'quickCreateMaintenanceModeEnabled'
  );

  if (!quickCreateMaintenanceModeEnabled) {
    return children;
  }

  return (
    <CenteredContent pt="100px" textAlign="center">
      <Text fontSize="3xl" fontWeight="medium" lineHeight="1.2">
        Sorry! We are under maintenance.
      </Text>
      <Text fontSize="lg" mt="2">
        Please check back soon.
      </Text>
    </CenteredContent>
  );
};
