import { useCurrentUserContext } from '@/contexts';
import { useIsMobileBreakpoint } from '@/hooks/useIsBreakpoint';
import type { DecoratedQuickCreateEntry } from '@/pages/QuickCreate/types';
import { useIntegrations } from '@/pages/Schedule/hooks/useIntegrations';
import { PlatformTypes } from '@/types/gql.generated';
import { Menu, MenuButton, MenuList } from '@/ui';
import { ensurePlatform } from '@/utils/platformLib';
import { usePlatformPinTooltip } from '../../../hooks/usePlatformPinTip';
import { SendButton } from '../SendButton';
import { getLabel } from '../sendButtonLib';
import { ProviderItem } from './Item';
import { PlatformWarning } from './PlatformWarning';

type Props = {
  entry?: DecoratedQuickCreateEntry;
  success: boolean;
  isDimmed: boolean;
  onCalendarClick: (platform: PlatformTypes) => void;
};

export const AddToCalendarMenu = ({
  entry,
  success,
  isDimmed,
  onCalendarClick,
}: Props) => {
  const isMobileBreakpoint = useIsMobileBreakpoint();
  const { currentUser } = useCurrentUserContext();
  const { data: integrations = [] } = useIntegrations();
  const { incrementMenuOpenCount } = usePlatformPinTooltip();
  const preferredPlatform = currentUser?.preferredPlatform || null;

  const isRecurring = !!entry?.rule;
  const hasLocation = !!entry?.locationWithPlace?.name;
  const showPlatformWarning = isRecurring || hasLocation;

  return (
    <Menu isLazy onOpen={incrementMenuOpenCount}>
      <MenuButton
        as={SendButton}
        isDimmed={isDimmed}
        success={success}
        label={getLabel({
          platform: PlatformTypes.OtherIcs,
          isMobileBreakpoint,
          preferredPlatform,
          success,
        })}
      />

      <MenuList maxW="275px" shadow="md" zIndex="3">
        {showPlatformWarning && (
          <PlatformWarning
            hasLocation={hasLocation}
            isRecurring={isRecurring}
          />
        )}

        <ProviderItem
          entry={entry}
          hasPlatformTip
          provider={ensurePlatform(
            PlatformTypes.GoogleIntegration,
            integrations
          )}
          onClick={onCalendarClick}
        >
          Google Calendar
        </ProviderItem>
        <ProviderItem
          entry={entry}
          provider={PlatformTypes.AppleIcs}
          onClick={onCalendarClick}
        >
          Apple
        </ProviderItem>
        <ProviderItem
          entry={entry}
          provider={PlatformTypes.OutlookLink}
          onClick={onCalendarClick}
        >
          Outlook
        </ProviderItem>
        <ProviderItem
          entry={entry}
          provider={PlatformTypes.OfficeLink}
          onClick={onCalendarClick}
        >
          Office 365
        </ProviderItem>
        <ProviderItem
          entry={entry}
          provider={PlatformTypes.OtherIcs}
          onClick={onCalendarClick}
        >
          Other
        </ProviderItem>
      </MenuList>
    </Menu>
  );
};
