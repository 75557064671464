import { IconRow } from '@/pages/QuickCreate/components/IconRow';
import type { DecoratedQuickCreateEntry } from '@/pages/QuickCreate/types';
import { Text } from '@/ui';
import type { QuickEntryChangeHandler } from '../../types';
import { LocationInput } from './LocationInput';

type Props = {
  entry: DecoratedQuickCreateEntry;
  readOnly: boolean;
  onChange: QuickEntryChangeHandler;
};

export const Location = ({ entry, readOnly, onChange }: Props) => {
  const handleChange = (name: string, googlePlaceId: string | null) => {
    onChange({
      locationWithPlace: {
        name,
        googlePlaceId,
      },
    });
  };

  return (
    <IconRow field="location" iconSize="20px">
      {readOnly ? (
        <Text fontWeight="medium" px="2">
          {entry.locationWithPlace?.name}
        </Text>
      ) : (
        <LocationInput
          _hover={{ borderColor: 'gray.200' }}
          borderColor="transparent"
          borderRadius="md"
          fontWeight="medium"
          h="auto"
          px="2"
          py="1"
          value={entry.locationWithPlace?.name ?? ''}
          onChange={handleChange}
        />
      )}
    </IconRow>
  );
};
