import { ChevronDownIcon } from '@chakra-ui/icons';
import { type ForwardedRef, forwardRef, useEffect } from 'react';
import { FaCog } from 'react-icons/fa';
import { VscCircleFilled } from 'react-icons/vsc';
import { useOpenSettingsModal } from '@/components/SettingsModal';
import { PlatformTypes } from '@/types/gql.generated';
import {
  Flex,
  Menu,
  MenuButton,
  MenuGroup,
  MenuItem,
  MenuList,
  Text,
  Icon,
  Portal,
  useDisclosure,
  type UseDisclosureProps,
  type ButtonProps,
  IconButton,
} from '@/ui';
import { usePickerIntegrations } from './hooks/usePickerIntegrations';
import type { Destination } from './types';

type Props = Omit<ButtonProps, 'onChange' | 'value'> & {
  value: Destination | null;
  onChange: (choice: Destination) => void;
};

const Picker = (
  { onChange, value, ...props }: Props,
  ref: ForwardedRef<UseDisclosureProps>
) => {
  const { openSettingsModal } = useOpenSettingsModal();
  const { integrations } = usePickerIntegrations();
  const disclosure = useDisclosure();

  useEffect(() => {
    if (typeof ref === 'function') {
      ref(disclosure);
    } else if (ref) {
      ref.current = disclosure;
    }
  }, [ref, disclosure]);

  return (
    <Menu
      isOpen={disclosure.isOpen}
      matchWidth
      onClose={disclosure.onClose}
      onOpen={disclosure.onOpen}
    >
      <MenuButton
        _hover={{ borderColor: 'gray.300' }}
        bg="white"
        borderRadius="md"
        borderWidth="1px"
        flexGrow="1"
        h="10"
        overflow="hidden" // required for text overflow to work
        pl="3"
        pr="2"
        type="button"
        {...props}
      >
        <Flex align="center" as="span" gap="4" justify="space-between">
          <Flex
            align="center"
            as="span"
            flexShrink="1"
            gap="3"
            justify="flex-start"
            minW="0"
          >
            {value && (
              <Icon
                as={VscCircleFilled}
                boxSize="20px"
                color={value.calendar.color}
              />
            )}
            <Text
              as="span"
              fontWeight={value ? 'normal' : 'bold'}
              overflow="hidden"
              textOverflow="ellipsis"
              whiteSpace="nowrap"
            >
              {value ? value.calendar.name : 'Choose a calendar'}
            </Text>
          </Flex>
          <ChevronDownIcon boxSize="20px" />
        </Flex>
      </MenuButton>
      <Portal>
        <MenuList maxH="16.9rem" overflow="auto" zIndex="4">
          {integrations.map(({ integration, suitableCalendars }) => {
            return (
              <MenuGroup
                fontWeight="normal"
                key={integration.id}
                opacity="0.6"
                title={integration.name}
              >
                {suitableCalendars.map((calendar) => {
                  return (
                    <MenuItem
                      isDisabled={calendar.readonly}
                      key={calendar.uri}
                      icon={
                        <Icon
                          as={VscCircleFilled}
                          boxSize="20px"
                          color={calendar.color}
                        />
                      }
                      onClick={() =>
                        onChange({
                          calendar,
                          integration,
                          platform: PlatformTypes.GoogleIntegration,
                        })
                      }
                    >
                      <Text
                        alignItems="center"
                        as="span"
                        display="flex"
                        fontWeight="medium"
                        gap="2"
                        pointerEvents="auto"
                      >
                        <Text as="span">{calendar.name}</Text>
                      </Text>
                    </MenuItem>
                  );
                })}
              </MenuGroup>
            );
          })}
          <IconButton
            aria-label="Settings"
            color="gray.600"
            colorScheme="dark"
            icon={<Icon as={FaCog} />}
            ml="auto"
            pos="absolute"
            right="1.5"
            size="xs"
            top="2"
            variant="ghost"
            onClick={() => openSettingsModal('calendars')}
          />
        </MenuList>
      </Portal>
    </Menu>
  );
};

export const GoogleCalendarPicker = forwardRef(Picker);
