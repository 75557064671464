import { useEffect, useState } from 'react';
import { useAnalytics } from '@/hooks/useAnalytics';
import { useLauncherAction } from '@/hooks/useLauncher';
import { PlatformTypes } from '@/types/gql.generated';
import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
} from '@/ui';
import { useBroadcastReceiver } from '../../extension/hooks/useBroadcast';
import { FadeInOut } from '../PromptForm/FadeInOut';
import { AuthBody } from './AuthBody';
import { GoogleIntegrationBody } from './GoogleIntegrationBody';
import { useAuthModal } from './store';
import { useHandlePreferredPlatformLaunch } from './useHandlePreferredPlatformLaunch';

export const AuthModal = () => {
  const { close, isOpen, platform, reason, launch } = useAuthModal();
  const initialPlatformCta = useHandlePreferredPlatformLaunch();
  const [platformCta, setPlatformCta] = useState(initialPlatformCta);
  const { trackEvent } = useAnalytics();

  useEffect(() => {
    if (isOpen) {
      trackEvent('lead-gate:show', { reason });
    }
  }, [isOpen, reason, trackEvent]);

  // track successful oauth authentications
  useLauncherAction('magic-auth-success', ({ reason }) => {
    trackEvent('lead-gate:success', {
      reason,
    });
  });

  useEffect(() => {
    if (initialPlatformCta) {
      setPlatformCta(initialPlatformCta);
    }
  }, [initialPlatformCta]);

  const handleAuthComplete = () => {
    trackEvent('lead-gate:success', {
      reason,
    });

    if (platform === PlatformTypes.GoogleLink) {
      setPlatformCta(platform);
    } else {
      close();
    }
  };

  useBroadcastReceiver('_ah_auth_success', handleAuthComplete);

  return (
    <Modal
      isCentered
      isOpen={isOpen}
      size={{ base: 'full', md: 'lg' }}
      onClose={close}
    >
      <ModalOverlay />
      <ModalContent
        layerStyle="magicBackgroundSecondary"
        pb="16"
        pt="6"
        px={{ base: '0', sm: '4', md: '8' }}
      >
        <ModalCloseButton />
        <ModalBody as={FadeInOut} flexDir="column" key={platformCta || 'auth'}>
          {platformCta === PlatformTypes.GoogleLink ? (
            <GoogleIntegrationBody onComplete={close} />
          ) : (
            <AuthBody
              launch={launch}
              platform={platform}
              reason={reason}
              onComplete={handleAuthComplete}
            />
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
