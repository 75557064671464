import { MotionFlex, type MotionFlexProps } from '@/ui';

export const CenteredContent = (props: MotionFlexProps) => {
  return (
    <MotionFlex
      direction="column"
      flex="1"
      maxW={{ md: '700px' }}
      mx="auto"
      px="4"
      w="100%"
      {...props}
    />
  );
};
