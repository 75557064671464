import { MenuButton as ChakraMenuButton, type MenuButtonProps } from '@/ui';

export const TriggerButton = (props: MenuButtonProps) => {
  return (
    <ChakraMenuButton
      _active={{ bg: 'gray.100' }}
      _hover={{ bg: 'gray.100' }}
      bg="none"
      borderRadius="lg"
      fontWeight="normal"
      h="9"
      minW="9"
      p="0"
      transition="all 0.1s ease-in-out"
      {...props}
    />
  );
};
