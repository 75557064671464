import { CloseIcon } from '@chakra-ui/icons';
import { DateTime } from 'luxon';
import {
  type ChangeEventHandler,
  type ComponentProps,
  type FocusEventHandler,
  type KeyboardEvent,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useIsBreakpoint } from '@/hooks/useIsBreakpoint';
import { TimeInput as CommonTimeInput } from '@/pages/Schedule/components';
import {
  Button,
  Text,
  IconButton,
  useUpdateEffect,
  usePrevious,
  Flex,
  Box,
  type ButtonProps,
  forwardRef,
} from '@/ui';

type Props = {
  startDate: DateTime;
  endDate: DateTime;
  isOnDay: boolean;
  onAddTimeClick: () => void;
  onRemoveTimeClick: () => void;
  onStartTimeChange: (time: DateTime) => void;
  onEndTimeChange: (time: DateTime) => void;
  onEndTimeBlur: () => void;
};

const toFormat = (date: DateTime) => date.toFormat('HH:mm');

export const TimeInput = ({
  startDate,
  endDate,
  isOnDay,
  onAddTimeClick,
  onRemoveTimeClick,
  onStartTimeChange,
  onEndTimeChange,
  onEndTimeBlur,
}: Props) => {
  const startInputRef = useRef<HTMLInputElement>(null);
  const endInputRef = useRef<HTMLInputElement>(null);
  const isSmBreakpoint = useIsBreakpoint('sm');

  const [startTime, setStartTime] = useState(() => toFormat(startDate));
  useEffect(() => {
    setStartTime(toFormat(startDate));
  }, [startDate]);

  const [endTime, setEndTime] = useState(() => toFormat(endDate));
  useEffect(() => {
    setEndTime(toFormat(endDate));
  }, [endDate]);

  const handleStartTimeChange: ChangeEventHandler<HTMLInputElement> = (event) =>
    setStartTime(event.currentTarget.value);

  const handleEndTimeChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    setEndTime(event.currentTarget.value);
  };

  const handleStartTimeBlur: FocusEventHandler<HTMLInputElement> = () => {
    const newStartTime = DateTime.fromFormat(startTime, 'HH:mm');

    if (newStartTime.isValid) {
      onStartTimeChange(newStartTime);
    } else {
      setStartTime(toFormat(startDate));
    }
  };

  const handleEndTimeBlur: FocusEventHandler<HTMLInputElement> = () => {
    const newEndTime = DateTime.fromFormat(endTime, 'HH:mm');

    if (newEndTime.isValid) {
      onEndTimeChange(newEndTime);
    } else {
      setEndTime(toFormat(endDate));
    }
  };

  const handleStartTimeKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      endInputRef.current?.focus();
    }
  };

  const handleEndTimeKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.currentTarget.blur();
      onEndTimeBlur();
    }
  };

  const prevIsOnDay = usePrevious(isOnDay);
  useUpdateEffect(() => {
    if (prevIsOnDay && !isOnDay) {
      startInputRef.current?.select();
    }
  }, [isOnDay, prevIsOnDay]);

  if (isOnDay) {
    return (
      <Box>
        <Link onClick={onAddTimeClick}>Add time</Link>
      </Box>
    );
  }

  return (
    <Flex align="center" gap="2">
      <TimeInputField
        aria-label="Start time"
        ref={startInputRef}
        value={startTime}
        onBlur={handleStartTimeBlur}
        onChange={handleStartTimeChange}
        onFocus={(event) => event.currentTarget.select()}
        onKeyDown={handleStartTimeKeyDown}
      />
      <Text variant="light">to</Text>
      <TimeInputField
        aria-label="End time"
        max="24:00"
        min={startTime}
        ref={endInputRef}
        value={endTime}
        onBlur={handleEndTimeBlur}
        onChange={handleEndTimeChange}
        onFocus={(event) => event.currentTarget.select()}
        onKeyDown={handleEndTimeKeyDown}
      />

      {isSmBreakpoint ? (
        <IconButton
          aria-label="Remove time"
          icon={<CloseIcon boxSize={2} />}
          size="xs"
          variant="secondary"
          onClick={onRemoveTimeClick}
        />
      ) : (
        <Link onClick={onRemoveTimeClick}>Remove</Link>
      )}
    </Flex>
  );
};

const TimeInputField = forwardRef(
  (props: ComponentProps<typeof CommonTimeInput>, ref) => (
    <CommonTimeInput
      _hover={{ borderColor: 'gray.300' }}
      borderColor="gray.100"
      borderRadius="md"
      required
      size="sm"
      textAlign="center"
      w="125px"
      {...props}
      ref={ref}
    />
  )
);

const Link = (props: ButtonProps) => (
  <Button
    color="customgray.mid"
    colorScheme="dark"
    size="sm"
    variant="link"
    {...props}
  />
);
