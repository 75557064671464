import { DateTime } from 'luxon';
import type { DecoratedQuickCreateEntry } from '@/pages/QuickCreate/types';

type EntryLike = Pick<
  DecoratedQuickCreateEntry,
  'startDate' | 'isOnDay' | 'timeZone'
>;

export const didConvertTimeZone = ({
  startDate,
  timeZone,
  isOnDay,
}: EntryLike): boolean => {
  const localOffset = DateTime.local().offset;
  const localZone = DateTime.local().zoneName;
  const entryOffset = startDate.setZone(timeZone).offset;

  return !isOnDay && localZone !== timeZone && localOffset !== entryOffset;
};
