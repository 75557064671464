import { useMutation } from '@tanstack/react-query';
import { gql } from 'graphql-request';
import { useCallback } from 'react';
import { gqlClient, queryClient } from '@/lib';
import type { QueryError } from '@/utils/errors';
import { createGetMagicJobsQueryKey } from '@/utils/queryKeys';
import { QuickEntriesJobFragment } from '../../fragments/QuickEntriesJobFragment';
import { createDecoratedQuickCreateEntryJob } from '../../utils/createDecoratedQuickCreateEntry';
import type {
  ReviewQuickEntriesJobMutation,
  ReviewQuickEntriesJobMutationVariables,
} from './useReviewQuickEntriesJob.generated';

const query = gql`
  ${QuickEntriesJobFragment}
  mutation ReviewQuickEntriesJob(
    $quickEntriesJobId: ID!
    $timeZone: TimeZone!
  ) {
    reviewQuickEntriesJob(
      quickEntriesJobId: $quickEntriesJobId
      timeZone: $timeZone
    ) {
      ...QuickEntriesJob
    }
  }
`;

export const useReviewQuickEntriesJob = () => {
  const { mutate, data, ...rest } = useMutation<
    ReviewQuickEntriesJobMutation,
    QueryError,
    ReviewQuickEntriesJobMutationVariables
  >({
    mutationFn: (variables) => {
      return gqlClient.request<
        ReviewQuickEntriesJobMutation,
        ReviewQuickEntriesJobMutationVariables
      >(query, variables);
    },
    onSuccess: (result) => {
      const data = result.reviewQuickEntriesJob;
      const job = createDecoratedQuickCreateEntryJob(data);
      queryClient.setQueryData(createGetMagicJobsQueryKey(), job);
    },
  });

  const reviewQuickEntriesJob = useCallback(
    (jobId: string, timeZone: string) => {
      mutate({ quickEntriesJobId: jobId, timeZone });
    },
    [mutate]
  );

  const job = data
    ? createDecoratedQuickCreateEntryJob(data.reviewQuickEntriesJob)
    : undefined;

  return {
    reviewQuickEntriesJob,
    job,
    ...rest,
  };
};
