import type { ReactElement } from 'react';
import { IoCheckmarkCircleOutline } from 'react-icons/io5';
import { PlatformIcon } from '@/components/PlatformIcon';
import { useCurrentUserContext } from '@/contexts/CurrentUserContext';
import { useIsMobileBreakpoint } from '@/hooks/useIsBreakpoint';
import type { PlatformTypes } from '@/types/gql.generated';
import {
  Button,
  type ButtonProps,
  Icon,
  Text,
  Tooltip,
  forwardRef,
} from '@/ui';
import { type LabelOptions, getLabel, getStandardLabel } from './sendButtonLib';

type Props = Omit<ButtonProps, 'children'> & {
  success?: boolean;
  isDimmed?: boolean;
  platform: PlatformTypes;
  label?: string;
  icon?: ReactElement;
};

export const SendButton = forwardRef((props: Props, ref) => {
  const isMobileBreakpoint = useIsMobileBreakpoint();
  const { currentUser } = useCurrentUserContext();
  const { isDimmed, platform, onClick, icon, success } = props;
  const preferredPlatform = currentUser?.preferredPlatform || null;

  const labelOptions: LabelOptions = {
    preferredPlatform,
    platform,
    success,
    isMobileBreakpoint,
  };

  const label = getLabel(labelOptions);
  const standardLabel = getStandardLabel(platform);
  const isIconOnly = isMobileBreakpoint || !!preferredPlatform;

  return (
    <Tooltip isDisabled={isIconOnly} label={standardLabel} placement="bottom">
      <Button
        _active={{ bg: 'white' }}
        aria-label={standardLabel}
        bg={isDimmed ? 'transparent' : 'white'}
        border="1px solid rgba(0, 0, 0, 0.15)"
        borderRadius="8"
        flex={{ base: 0, md: preferredPlatform ? 0 : 1 }}
        fontSize="sm"
        fontWeight="bold"
        gap="2"
        h={{ base: 9, md: preferredPlatform ? 9 : 10 }}
        minW="9"
        pos="relative"
        px="2"
        ref={ref}
        variant="secondary"
        onClick={onClick}
        onFocus={(event) => {
          // https://github.com/chakra-ui/chakra-ui/issues/5304
          event.preventDefault();
        }}
      >
        {icon || <PlatformIcon platform={platform} />}

        {!isMobileBreakpoint && !preferredPlatform && (
          <Text overflow="hidden" textOverflow="ellipsis" whiteSpace="nowrap">
            {label}
          </Text>
        )}

        {success && isIconOnly && (
          <Icon
            as={IoCheckmarkCircleOutline}
            boxSize="18px"
            color="white"
            pos="absolute"
            right="-8px"
            sx={{ path: { fill: 'green' } }}
            top="-8px"
          />
        )}
      </Button>
    </Tooltip>
  );
});
