import type { ReactNode } from 'react';
import { Box, type BoxProps, Text } from '@/ui';

type Props = BoxProps & {
  message: string;
  cta: ReactNode;
};

export const ErrorMessage = ({ message, cta, ...props }: Props) => {
  return (
    <Box {...props}>
      <Text color="#716ADD" fontWeight="semibold" lineHeight="normal">
        {message}
      </Text>
      {cta}
    </Box>
  );
};
