import { useCalendarAuth } from '@/components/auth/hooks/useCalendarAuth';
import { useToast } from '@/hooks/useToast';
import { Sentry } from '@/lib';
import { useQuickCreateContext } from '@/pages/QuickCreate/contexts';
import type { BoxProps } from '@/ui';
import { usePickerIntegrations } from '../../hooks/usePickerIntegrations';
import { ErrorButton } from './ErrorButton';
import { ErrorMessage } from './ErrorMessage';

type Props = BoxProps & {
  onReportClick?: () => void;
};

export const IntegrationErrors = ({ onReportClick, ...props }: Props) => {
  const toast = useToast();
  const { job } = useQuickCreateContext();
  const { integrations, unusableGoogleIntegrationCount } =
    usePickerIntegrations();
  const { handleCalendarAuth } = useCalendarAuth({ quickCreateJobId: job?.id });

  const handleReportClick = () => {
    Sentry.captureMessage('Invalid Google Calendar state', {
      extra: {
        integrations: integrations,
      },
    });
    toast.info({
      description: 'Thank you for reporting this issue',
    });
    onReportClick?.();
  };

  if (unusableGoogleIntegrationCount > 0 && integrations.length === 0) {
    return (
      <ErrorMessage
        {...props}
        message="We aren't able to access your Google Calendar. Please ensure that access to your calendar is approved for Agenda Hero and try again."
        cta={
          <ErrorButton onClick={handleCalendarAuth}>Manage access</ErrorButton>
        }
      />
    );
  }

  if (integrations.length === 0) {
    return (
      <ErrorMessage
        {...props}
        cta={<ErrorButton onClick={handleReportClick}>Report</ErrorButton>}
        message="We couldn't find a supported calendar in your account."
      />
    );
  }

  return null;
};
