import {
  Autocomplete,
  type AutocompleteProps,
  type LoadScriptProps,
  useJsApiLoader,
} from '@react-google-maps/api';
import {
  type ChangeEventHandler,
  type KeyboardEventHandler,
  useRef,
} from 'react';
import { config } from '@/config';
import { Box, Input, type InputProps } from '@/ui';

type Props = Omit<InputProps, 'onChange' | 'onSelect'> & {
  value: string;
  onChange: (value: string, placeId: string | null) => void;
  onLocationFocus?: () => void;
  onLocationBlur?: () => void;
};

const libraries: LoadScriptProps['libraries'] = ['places'];
const fields: AutocompleteProps['fields'] = ['place_id', 'name', 'geometry'];

export const LocationInput = ({
  value,
  onChange,
  onLocationFocus,
  onLocationBlur,
  ...props
}: Props) => {
  const inputRef = useRef<HTMLInputElement | null>(null);
  const autocompleteRef = useRef<google.maps.places.Autocomplete | null>(null);

  const { isLoaded } = useJsApiLoader({
    googleMapsApiKey: config.googleCloud.apiKey,
    libraries,
  });

  const handleLoad = (instance: google.maps.places.Autocomplete) => {
    autocompleteRef.current = instance;
  };

  const handlePlaceChange = () => {
    if (inputRef.current) {
      const placeId = autocompleteRef.current?.getPlace()?.place_id ?? null;
      onChange(inputRef.current.value, placeId);
    }
  };

  const handleKeyDown: KeyboardEventHandler<HTMLInputElement> = (event) => {
    // Enter to choose a place, not to submit the underlying form
    if (event.key === 'Enter') {
      event.preventDefault();
    }
  };

  const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const placeId = autocompleteRef.current?.getPlace()?.place_id ?? null;
    onChange(event.currentTarget.value, placeId);
  };

  const input = (
    <Input
      aria-label="Location"
      placeholder="Location"
      ref={inputRef}
      value={value}
      onBlur={onLocationBlur}
      onChange={handleChange}
      onFocus={onLocationFocus}
      onKeyDown={handleKeyDown}
      {...props}
    />
  );

  return (
    <Box flex="1">
      {isLoaded ? (
        <Autocomplete
          fields={fields}
          onLoad={handleLoad}
          onPlaceChanged={handlePlaceChange}
        >
          {input}
        </Autocomplete>
      ) : (
        input
      )}
    </Box>
  );
};
