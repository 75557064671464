import { Form } from '@feathery/react';
import { AnimatePresence } from 'framer-motion';
import { useEffect, useState } from 'react';
import { MotionFlex, type MotionFlexProps } from '@/ui';
import {
  Flex,
  Button,
  Popover,
  PopoverArrow,
  PopoverContent,
  PopoverTrigger,
  forwardRef,
} from '@/ui';
import { useFeathery } from '../../hooks/useFeathery';
import Star from './star.svg?react';

const MotionContainer = forwardRef((props: MotionFlexProps, ref) => (
  <MotionFlex
    animate={{ opacity: 1 }}
    exit={{ opacity: 0 }}
    ref={ref}
    transition={{ duration: 0.2 }}
    {...props}
  />
));

export const ResultsFeedbackForm = (props: MotionFlexProps) => {
  const [didSubmit, setSubmitted] = useState(false);
  const [isHidden, setHidden] = useState(false);
  const { ready } = useFeathery();

  useEffect(() => {
    let timerId: NodeJS.Timeout;
    if (didSubmit) {
      timerId = setTimeout(() => setHidden(true), 3000);
    }
    return () => {
      clearTimeout(timerId);
    };
  }, [didSubmit]);

  if (!ready) {
    return null;
  }

  return (
    <AnimatePresence mode="popLayout">
      {isHidden ? null : didSubmit ? (
        <MotionContainer color="purple.600" fontWeight="bold" key="success">
          Thank you! 🎉
        </MotionContainer>
      ) : (
        <MotionContainer
          alignItems="center"
          flexDir="column"
          fontWeight="medium"
          gap="3"
          key="default"
          lineHeight="normal"
          {...props}
        >
          <Flex gap="0.5">
            <Star />
            <Star />
            <Star />
            <Star />
            <Star />
          </Flex>

          <Flex lineHeight="normal">
            Did AI get it right?
            <Popover offset={[-100, 12]}>
              <PopoverTrigger>
                <Button
                  colorScheme="dark"
                  fontSize="md"
                  ml="1"
                  textDecor="underline"
                  variant="link"
                >
                  Rate the results!
                </Button>
              </PopoverTrigger>

              <PopoverContent>
                <PopoverArrow />
                <Form
                  formId="aoIBlv"
                  onFormComplete={() => setSubmitted(true)}
                />
              </PopoverContent>
            </Popover>
          </Flex>
        </MotionContainer>
      )}
    </AnimatePresence>
  );
};
