export const cleanHTML = (html: string): string => {
  // replace consecutive break tags with a single break tag
  let cleanHTML = html.replace(/(<br>\s*){2,}/gi, '<br>');

  // remove break tags immediately after opening p tags while preserving inner contents
  cleanHTML = cleanHTML.replace(/(<p>\s*)<br>(.*?)<\/p>/gi, '$1$2</p>');

  // remove break tags immediately before closing p tags while preserving inner contents
  cleanHTML = cleanHTML.replace(/(<p>.*?)<br>\s*<\/p>/gi, '$1</p>');

  // remove paragraph tags that only have a break tag in them
  cleanHTML = cleanHTML.replace(/<p><br><\/p>/gi, '');

  // remove empty paragraph tags
  cleanHTML = cleanHTML.replace(/<p><\/p>/gi, '');

  return cleanHTML;
};
