import { init, setFieldValues, updateUserId } from '@feathery/react';
import LogRocket from 'logrocket';
import { useEffect, useState } from 'react';
import { useMount } from 'react-use';
import { v4 as uuidv4 } from 'uuid';
import { config } from '@/config';
import { useCurrentUserContext } from '@/contexts';
import { useQuickCreateContext } from '../contexts';

const createUserId = (): string => {
  // each user/submission is given a uniquely generated ID. this is the
  // only way to ensure the same user can submit feedback multiple times
  // both per-job and across-jobs.
  return uuidv4();
};

export const useFeathery = () => {
  const { job } = useQuickCreateContext();
  const { currentUser } = useCurrentUserContext();
  const [ready, setReady] = useState(false);

  useMount(() => {
    init(config.feathery.sdkKey, {
      userId: createUserId(),
    });
    setReady(true);
  });

  useEffect(() => {
    // give the user a unique ID every time the job changes so
    // the form can be submitted by the same user multiple times
    updateUserId(createUserId());

    setFieldValues({
      jobId: job?.id ?? null,
      ahUserId: currentUser?.id ?? null,
    });

    LogRocket.getSessionURL((sessionUrl) => {
      setFieldValues({
        logrocketSessionUrl: sessionUrl,
      });
    });
  }, [job, currentUser]);

  return { ready };
};
