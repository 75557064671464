import type { BaseEmoji } from 'emoji-mart';
import { useState } from 'react';
import { EmojiSelect } from '@/pages/Schedule/components';
import { Box, Menu, type MenuButtonProps, MenuItem, MenuList } from '@/ui';
import { TriggerButton } from './TriggerButton';

type Props = MenuButtonProps & {
  value: string | null | undefined;
  onEmojiSelected: (emoji: string | null) => void;
};

export const EmojiPicker = ({ value, onEmojiSelected, ...props }: Props) => {
  const [forceShowPicker, setForceShowPicker] = useState(false);

  return (
    <Menu
      closeOnSelect={false}
      isLazy
      onClose={() => setForceShowPicker(false)}
    >
      {({ isOpen, onClose }) => (
        <>
          <TriggerButton filter={value ? 'none' : 'grayscale(100%)'} {...props}>
            {value || <Box>😄</Box>}
          </TriggerButton>

          <MenuList>
            {value && !forceShowPicker ? (
              <>
                <MenuItem
                  onClick={() => {
                    onEmojiSelected(null);
                    onClose();
                  }}
                >
                  Remove
                </MenuItem>
                <MenuItem onClick={() => setForceShowPicker(true)}>
                  Change
                </MenuItem>
              </>
            ) : isOpen ? (
              <EmojiSelect
                onSelect={(emoji: BaseEmoji) => {
                  onEmojiSelected(emoji.native);
                  setForceShowPicker(false);
                  onClose();
                }}
              />
            ) : null}
          </MenuList>
        </>
      )}
    </Menu>
  );
};
