import type { DateTime } from 'luxon';
import type { DecoratedQuickCreateEntry } from '@/pages/QuickCreate/types';
import { Flex } from '@/ui';
import Wand from './wand.svg?react';

type Props = {
  entry: DecoratedQuickCreateEntry;
};

const formatTime = (date: DateTime, zone: string, addZone = false): string => {
  const format = addZone ? 'h:mm a ZZZZ' : 'h:mm a';

  return date
    .setZone(zone)
    .toFormat(format)
    .replace(/AM|PM/g, ($1) => $1.toLowerCase())
    .replace(/:00/g, '');
};

export const ConvertedFromTime = ({ entry }: Props) => {
  return (
    <Flex
      color="gray.800"
      fontSize="sm"
      fontWeight="normal"
      gap="1"
      pos="relative"
      top="2px"
    >
      <Wand />
      Converted from {formatTime(entry.startDate, entry.timeZone)} &ndash;{' '}
      {formatTime(entry.endDate, entry.timeZone, true)}
    </Flex>
  );
};
