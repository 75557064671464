import { useState } from 'react';
import { useCurrentUserContext } from '@/contexts/CurrentUserContext';
import { useAnalytics } from '@/hooks/useAnalytics';
import { useIsMobile, useIsMobileBreakpoint } from '@/hooks/useIsBreakpoint';
import { usePlatformExports } from '@/pages/QuickCreate/hooks/usePlatformExports';
import type { DecoratedQuickCreateEntry } from '@/pages/QuickCreate/types';
import { useIntegrations } from '@/pages/Schedule/hooks/useIntegrations';
import { PlatformTypes } from '@/types/gql.generated';
import { Box, Flex, type FlexProps, Text } from '@/ui';
import { ensurePlatform } from '@/utils/platformLib';
import { useIsSharePage } from '../../hooks/useIsSharePage';
import { useAuthModal } from '../AuthModal';
import { ActivityDrawer } from './ActivityDrawer';
import { AddToCalendarMenu } from './AddToCalendarMenu';
import { useAddToCalendarGate } from './hooks/useAddToCalendarGate';
import { PinSuccessTooltip } from './PinSuccessTooltip';
import { SendButton } from './SendButton';
import {
  getInitialSendState,
  getPlatformAddUrl,
  hasDrawer,
  hasExported,
  hasExportedToOther,
  hasSentToOther,
  isOtherPlatform,
} from './sendButtonLib';

type Props = FlexProps & {
  entry?: DecoratedQuickCreateEntry;
  isFirstItem?: boolean;
};

export const SendEventButtons = ({ entry, isFirstItem, ...props }: Props) => {
  const isMobileBreakpoint = useIsMobileBreakpoint();
  const { isMobile } = useIsMobile();
  const { isAuthenticated, currentUser } = useCurrentUserContext();
  const { data: integrations = [] } = useIntegrations();
  const preferredPlatform = currentUser?.preferredPlatform
    ? ensurePlatform(currentUser.preferredPlatform, integrations)
    : null;

  const { trackEvent } = useAnalytics();
  const [activity, setActivity] = useState<PlatformTypes | null>(null);
  const { open: openAuthModal } = useAuthModal();
  const { isGated, increment: incrementAddToCalendarCount } =
    useAddToCalendarGate();
  const { platformExports } = usePlatformExports();
  const { isSharePage } = useIsSharePage();

  const [sentPlatforms, setSentPlatforms] = useState(getInitialSendState());

  const setSendToPlatform = (platform: PlatformTypes) => {
    setSentPlatforms({ ...sentPlatforms, [platform]: true });

    if (!isSharePage) {
      incrementAddToCalendarCount();
    }
  };

  const toggleActivity = (activity: PlatformTypes) => {
    setActivity((prevActivity) =>
      prevActivity === activity ? null : activity
    );
  };

  const handleSendToAgendaHeroClick = () => {
    trackEvent('quick-create:click send to agenda hero');
    if (isAuthenticated) {
      toggleActivity(PlatformTypes.AgendaHero);
    } else {
      openAuthModal();
    }
  };

  const handleProviderClick = (provider: PlatformTypes) => {
    if (!isSharePage && isGated) {
      openAuthModal({
        reason: 'add-to-calendar',
        platform: provider,
      });
    } else if (entry) {
      const url = getPlatformAddUrl(entry, provider, isMobile);

      trackEvent(`quick-create:click add to calendar`, {
        platform: provider,
        isPreferred: provider === preferredPlatform,
        hasPreferred: Boolean(preferredPlatform),
      });

      if (url) {
        window.open(url, '_blank');
        setSendToPlatform(provider);
      }

      if (provider === PlatformTypes.AgendaHero) {
        return handleSendToAgendaHeroClick();
      }

      if (provider === preferredPlatform) {
        trackEvent('quick-create:click add to preferred', {
          platform: preferredPlatform!,
        });
      }

      if (hasDrawer(provider)) {
        toggleActivity(provider);
      } else {
        setActivity(provider);
      }
    }
  };

  const isPreferredDimmed =
    !!activity && hasDrawer(activity) && activity !== preferredPlatform;
  const isAgendaHeroDimmed =
    !!activity && hasDrawer(activity) && activity !== PlatformTypes.AgendaHero;
  const isEmailDimmed =
    !!activity && hasDrawer(activity) && activity !== PlatformTypes.Email;
  const isOtherDimmed =
    !!activity && hasDrawer(activity) && !isOtherPlatform(activity);

  const successOther =
    hasSentToOther(sentPlatforms, preferredPlatform) ||
    Boolean(
      entry && hasExportedToOther(platformExports, entry, preferredPlatform)
    );

  return (
    <Flex direction="column" flex="1">
      <Flex
        align="center"
        gap="2"
        justify={{
          base: 'flex-end',
          md: preferredPlatform ? 'flex-end' : 'space-between',
        }}
        {...props}
      >
        {(isMobileBreakpoint || preferredPlatform) && (
          <Flex align="center">
            <Text
              fontSize={{ base: 'sm', md: 'md' }}
              fontWeight="bold"
              mr={{ base: 2, md: 3 }}
            >
              Add to:
            </Text>

            {preferredPlatform && (
              <>
                <PinSuccessTooltip
                  entry={entry}
                  isDisabled={!entry}
                  showOnUnspecifiedEntry={isFirstItem}
                >
                  <SendButton
                    isDimmed={isPreferredDimmed}
                    platform={ensurePlatform(preferredPlatform, integrations)}
                    success={
                      sentPlatforms[preferredPlatform] ||
                      Boolean(
                        entry &&
                          hasExported(
                            platformExports,
                            entry,
                            ensurePlatform(preferredPlatform, integrations)
                          )
                      )
                    }
                    onClick={() => handleProviderClick(preferredPlatform)}
                  />
                </PinSuccessTooltip>

                <Box bg="gray.300" h="7" ml="3" mr="1" w="1px" />
              </>
            )}
          </Flex>
        )}

        <SendButton
          isDimmed={isAgendaHeroDimmed}
          platform={PlatformTypes.AgendaHero}
          success={
            sentPlatforms.AGENDA_HERO ||
            Boolean(
              entry &&
                hasExported(platformExports, entry, PlatformTypes.AgendaHero)
            )
          }
          onClick={() => handleProviderClick(PlatformTypes.AgendaHero)}
        />

        <AddToCalendarMenu
          entry={entry}
          isDimmed={isOtherDimmed}
          success={successOther}
          onCalendarClick={handleProviderClick}
        />

        <SendButton
          isDimmed={isEmailDimmed}
          platform={PlatformTypes.Email}
          success={
            sentPlatforms.EMAIL ||
            Boolean(
              entry && hasExported(platformExports, entry, PlatformTypes.Email)
            )
          }
          onClick={() => handleProviderClick(PlatformTypes.Email)}
        />
      </Flex>

      <ActivityDrawer
        activity={activity}
        entry={entry}
        onComplete={setSendToPlatform}
      />
    </Flex>
  );
};
