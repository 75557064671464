import { Flex, Text } from '@/ui';
import { Card } from './Card';
import { LoadingText } from './LoadingText';

export const LoadingCard = () => {
  return (
    <Card gap="4" minH="140px">
      <Flex align="center" direction="column" flex="1" justify="center">
        <Text fontSize="4xl">🔮</Text>
        <LoadingText color="gray.500" fontSize="lg" fontWeight="bold" w="180px">
          Working our magic
        </LoadingText>
      </Flex>
    </Card>
  );
};
