import { Link, Flex, type FlexProps } from '@/ui';
import { useQuickCreateContext } from '../../contexts';
import { useIsExtension } from '../../hooks/useIsExtension';
import { useIsSharePage } from '../../hooks/useIsSharePage';
import android from './assets/android.png';
import apple from './assets/apple.png';
import chrome from './assets/chrome.png';
import { PlatformBadge } from './components/PlatformBadge';

export const Footer = (props: FlexProps) => {
  const { state } = useQuickCreateContext();
  const { isExtension } = useIsExtension();
  const { isSharePage } = useIsSharePage();

  return (
    <Flex
      align="center"
      as="footer"
      color="#766C8A"
      direction="column"
      gap="10"
      justify="space-between"
      maxW="960px"
      mx="auto"
      px={{ md: 14, lg: 0 }}
      py={isExtension ? 8 : 12}
      textAlign="center"
      {...props}
    >
      <Flex gap="4">
        <Link
          color="currentcolor"
          fontSize="sm"
          href="mailto:support@agendahero.com"
        >
          Support
        </Link>
        <Link
          color="currentcolor"
          fontSize="sm"
          target={isExtension ? '_blank' : undefined}
          href={
            isExtension
              ? 'https://agendahero.com/magic-faq#chrome-extension'
              : 'https://agendahero.com/magic-faq'
          }
        >
          Tips &amp; FAQ
        </Link>
        <Link
          color="currentcolor"
          fontSize="sm"
          href="/privacy"
          target={isExtension ? '_blank' : undefined}
        >
          Privacy
        </Link>
        <Link
          color="currentcolor"
          fontSize="sm"
          href="/tos"
          target={isExtension ? '_blank' : undefined}
        >
          Terms
        </Link>
      </Flex>

      {!isExtension && !isSharePage && state === 'default' && (
        <Flex gap={{ base: 2, md: 3 }} px="4">
          <PlatformBadge
            alt="iOS App Store"
            href="https://apps.apple.com/us/app/agenda-hero/id6480013905"
            src={apple}
          />
          <PlatformBadge
            alt="Google Play"
            href="https://play.google.com/store/apps/details?id=com.agendahero.app"
            src={android}
          />
          <PlatformBadge
            alt="Chome Web Store"
            href="https://chromewebstore.google.com/detail/agenda-hero-for-chrome/fglfichcambfnfmcjmppcnojneccedne"
            src={chrome}
          />
        </Flex>
      )}
    </Flex>
  );
};
