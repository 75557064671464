import { Box, Text } from '@/ui';

type Props = {
  numEntries: number;
  byline?: string | null;
};

export const HeadingText = ({
  numEntries,
  byline = 'Review and edit as needed',
}: Props) => {
  return (
    <Box>
      <Text fontSize="22px" fontWeight="bold" textStyle="magic">
        Voila! {numEntries} event{numEntries === 1 ? '' : 's'}
      </Text>

      {byline && (
        <Text fontWeight="medium" mt="0.5">
          {byline}
        </Text>
      )}
    </Box>
  );
};
