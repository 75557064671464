import { persist } from 'zustand/middleware';
import { shallow } from 'zustand/shallow';
import { createWithEqualityFn } from 'zustand/traditional';
import { useCurrentUserContext } from '@/contexts';

type State = {
  count: number;
  increment: () => void;
};

const useStore = createWithEqualityFn<State>()(
  persist(
    (set, get) => ({
      count: 0,
      increment: () => set({ count: get().count + 1 }),
    }),
    {
      name: '_ah_add_to_calendar_gate',
    }
  ),
  shallow
);

export const useAddToCalendarGate = () => {
  const { count, increment } = useStore();
  const { isAuthenticated } = useCurrentUserContext();
  const isGated = !isAuthenticated && count >= 3;

  return { isGated, increment };
};
