import { useEffect, useMemo, useState } from 'react';
import { shallow } from 'zustand/shallow';
import { createWithEqualityFn } from 'zustand/traditional';

type BootstrappedData = {
  jobId?: string;
  userId?: string;
  heading?: string;
  body?: string;
  image?: string;
};

type State = {
  consumed: boolean;
  setConsumed: () => void;
};

/**
 * Boostrapped data needs to be marked as consumed so we don't unintentionally
 * re-use it when the user navigates around the app. This is necessary because
 * the home route of magic looks at the bootstrap to support the `/?e={jobId}`
 * URL, and since the `/p` and `/s` routes also use the bootstrap, switching
 * to the home view from one of these paths will trigger the app to consume
 * the bootstrap again.
 *
 * For example:
 * 1. Load a `/p/:scheduleId` url while logged in
 * 2. Go to the Schedules tab
 * 3. Go back to the Magic tab
 *
 * Without this code you'd see a 404 error because the magic home page would
 * try to load the bootstrapped job ID, but this bootstrap was meant for
 * and already consumed by the detail page.
 *
 * or:
 *
 * 1. Load a `/s/:jobId` url while logged in
 * 2. Go to the Schedules tab
 * 3. Go back to the Magic tab
 *
 * Without this code you'd see the job loaded as if you ran the job yourself
 * and not as a job that was shared with you, for the same reason as above.
 */
const useConsumed = createWithEqualityFn<State>()(
  (set) => ({
    consumed: false,
    setConsumed: () => set({ consumed: true }),
  }),
  shallow
);

const getBootstrappedValue = (): string | undefined => {
  const element = document.querySelector<HTMLInputElement>('#data');
  return element?.value;
};

export const useBootstrappedData = (): {
  data: BootstrappedData | undefined;
  setConsumed: () => void;
  hasBootstrap: boolean;
} => {
  const { consumed, setConsumed } = useConsumed();

  // keep the boostrapped data around in state for as long
  // as this component is mounted. otherwise, properties other
  // than `jobId` will be lost as soon as the data has been marked
  // as consumed (like title, description, etc.)
  const [data, setData] = useState<BootstrappedData>();

  useEffect(() => {
    // don't reparse data next time this component is mounted
    // if it's already been consumed
    if (consumed) {
      return;
    }
    try {
      const value = getBootstrappedValue();

      if (value) {
        const data = JSON.parse(decodeURI(value)) as BootstrappedData;
        setData(data);
      }
    } catch (err) {
      return undefined;
    }
  }, [consumed]);

  // Did the page load with bootstrapped data?
  // Checking `data` directly works but can lead to UI jank because it takes an
  // extra render cycle before it becomes available.
  const hasBootstrap = useMemo(() => {
    return consumed ? false : Boolean(getBootstrappedValue());
  }, [consumed]);

  return {
    data,
    hasBootstrap,
    setConsumed,
  };
};
