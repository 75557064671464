import { Image, type ImageProps, Link } from '@/ui';

type Props = ImageProps & {
  href: string;
};

export const PlatformBadge = ({ href, ...props }: Props) => {
  return (
    <Link href={href} target="_blank">
      <Image maxH={{ base: '32px', md: '44px' }} {...props} />
    </Link>
  );
};
