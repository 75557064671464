import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { getLocalTimeZone } from '@/utils/dates';
import { useBootstrappedData } from './useBootstrappedData';
import { useReviewQuickEntriesJob } from './useReviewQuickEntriesJob';

export const useHydrateBootstrappedJob = () => {
  const { state } = useLocation();
  const { data, setConsumed, hasBootstrap } = useBootstrappedData();
  const bootstrappedJobId = data?.jobId || state?.jobId;
  const { reviewQuickEntriesJob, job, error, isIdle, isPending } =
    useReviewQuickEntriesJob();

  useEffect(() => {
    if (bootstrappedJobId) {
      // This sets the time zone for the job (only relevant for email jobs;
      // for all other job types it's a no-op) AND fetches the job data
      const timeZone = getLocalTimeZone();
      reviewQuickEntriesJob(bootstrappedJobId, timeZone);
      setConsumed();
    }
  }, [bootstrappedJobId, reviewQuickEntriesJob, setConsumed]);

  // If there's a bootstrapped job, default loading to true until the effect kicks
  // in to fetch the job. Otherwise it thrashes from false -> true -> false
  const isLoading = hasBootstrap && isIdle ? true : isPending;

  return { job, error, isLoading };
};
