import { useMemo } from 'react';
import {
  type DecoratedIntegration,
  useIntegrations,
} from '@/pages/Schedule/hooks/useIntegrations';
import { type Calendar, IntegrationType } from '@/types/gql.generated';
import {
  getSortedCalendars,
  isSuitableIntegration,
  selectGoogleIntegrations,
} from '@/utils/googleCalendar';

type Value = {
  isLoading: boolean;
  integrations: {
    integration: DecoratedIntegration;
    suitableCalendars: Calendar[];
  }[];
  unusableGoogleIntegrationCount: number;
};

export const usePickerIntegrations = (): Value => {
  const { data: allIntegrations = [], isLoading } = useIntegrations();

  const usableIntegrations = useMemo(() => {
    const integrations = selectGoogleIntegrations(allIntegrations);

    return integrations.map((integration) => {
      const suitableCalendars = getSortedCalendars(integration).filter(
        (calendar) => !calendar.readonly
      );
      return { integration, suitableCalendars };
    });
  }, [allIntegrations]);

  const unusableGoogleIntegrationCount = useMemo(() => {
    return allIntegrations.filter(
      (integration) =>
        integration.type === IntegrationType.GoogleCalendar &&
        !isSuitableIntegration(integration)
    ).length;
  }, [allIntegrations]);

  return {
    integrations: usableIntegrations,
    unusableGoogleIntegrationCount,
    isLoading,
  };
};
