import { AnimateChangeInHeight } from '@/components/AnimateChangeInHeight';
import { useCurrentUserContext } from '@/contexts';
import type { DecoratedQuickCreateEntry } from '@/pages/QuickCreate/types';
import { PlatformTypes } from '@/types/gql.generated';
import { Box } from '@/ui';
import { GoogleExportForm } from '../GoogleCalendar/GoogleExportForm';
import { FadeInOut } from '../PromptForm/FadeInOut';
import { AddToAgendaHeroForm } from './AddToAgendaHeroForm';
import { EmailForm } from './EmailForm';

type Props = {
  entry?: DecoratedQuickCreateEntry;
  activity: PlatformTypes | null;
  onComplete: (activity: PlatformTypes) => void;
};

export const ActivityDrawer = ({ entry, activity, onComplete }: Props) => {
  const { isAuthenticated } = useCurrentUserContext();

  if (!entry) {
    return null;
  }

  return (
    <AnimateChangeInHeight>
      <FadeInOut key={activity || 'none'} mt="2">
        {activity === PlatformTypes.AgendaHero && isAuthenticated && (
          <Box flexGrow="1" pb="2">
            <AddToAgendaHeroForm
              entry={entry}
              onSuccess={() => onComplete(PlatformTypes.AgendaHero)}
            />
          </Box>
        )}

        {activity === PlatformTypes.Email && (
          <Box flexGrow="1" pb="2">
            <EmailForm
              entry={entry}
              onSuccess={() => onComplete(PlatformTypes.Email)}
            />
          </Box>
        )}

        {activity === PlatformTypes.GoogleIntegration && (
          <Box flexGrow="1" pb="2">
            <GoogleExportForm
              entry={entry}
              onSuccess={() => onComplete(PlatformTypes.GoogleIntegration)}
            />
          </Box>
        )}
      </FadeInOut>
    </AnimateChangeInHeight>
  );
};
