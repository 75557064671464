import { darken } from 'color2k';
import { forwardRef, Button, type ButtonProps } from '@/ui';

const rootColor = '#383732';

const disabledStyles = {
  bg: rootColor,
  opacity: 0.4,
  cursor: 'default',
};
const focusStyles = {
  bg: darken(rootColor, 0.1),
  outline: 'none',
  _disabled: disabledStyles,
};
const activeStyles = {
  bg: 'black',
  _disabled: disabledStyles,
};
const hoverStyles = {
  bg: darken(rootColor, 0.1),
  _disabled: disabledStyles,
  _active: activeStyles,
};

export const SubmitButton = forwardRef((props: ButtonProps, ref) => {
  return (
    <Button
      _active={activeStyles}
      _disabled={disabledStyles}
      _focusVisible={focusStyles}
      _hover={hoverStyles}
      bg={rootColor}
      borderRadius="xl"
      color="white"
      fontSize={{ base: 'sm', md: 'md' }}
      fontWeight="bold"
      lineHeight="1"
      ref={ref}
      size={{ base: 'md', md: 'lg' }}
      type="submit"
      w="100%"
      {...props}
    />
  );
});
