import {
  type ChangeEventHandler,
  type FocusEventHandler,
  type ForwardedRef,
  forwardRef,
  useLayoutEffect,
  useState,
} from 'react';
import ReactTextareaAutosize, {
  type TextareaAutosizeProps,
} from 'react-textarea-autosize';
import type { DecoratedQuickCreateEntry } from '@/pages/QuickCreate/types';
import {
  Flex,
  type FlexProps,
  Text,
  Textarea,
  forwardRef as chakraForwardRef,
} from '@/ui';
import type { QuickEntryChangeHandler } from '../types';
import { EmojiPicker } from './EmojiPicker';

type Props = Omit<FlexProps, 'onChange'> & {
  entry: DecoratedQuickCreateEntry;
  readOnly?: boolean;
  onChange: QuickEntryChangeHandler;
};

// https://github.com/Andarist/react-textarea-autosize/issues/337
const TextAreaAutosize = forwardRef(function TextAreaAutosizeWithRef(
  props: TextareaAutosizeProps,
  ref: ForwardedRef<HTMLTextAreaElement>
) {
  const [, setIsRerendered] = useState(false);
  useLayoutEffect(() => setIsRerendered(true), []);
  return <ReactTextareaAutosize {...props} ref={ref} />;
});

export const Title = chakraForwardRef(
  ({ entry, readOnly, onChange, ...flexProps }: Props, ref) => {
    const handleChange: ChangeEventHandler<HTMLTextAreaElement> = (event) => {
      const value = event.currentTarget.value;
      onChange({ title: value });
    };

    const handleSelect = (emoji: string | null) => {
      onChange({ emoji });
    };

    const handleBlur: FocusEventHandler<HTMLTextAreaElement> = (event) => {
      if (!event.target.value.trim().length) {
        onChange({ title: 'Untitled event' });
      }
    };

    return (
      <Flex align="center" ref={ref} {...flexProps}>
        {readOnly ? (
          <Flex align="center" gap="2">
            {entry.emoji && (
              <Text fontSize="22px" minW="9" ml="-2" textAlign="center">
                {entry.emoji}
              </Text>
            )}
            <Text fontSize="lg" fontWeight="bold">
              {entry.title}
            </Text>
          </Flex>
        ) : (
          <>
            <EmojiPicker
              fontSize="22px"
              ml="-2"
              pos="relative"
              top="1px"
              value={entry.emoji ?? undefined}
              onEmojiSelected={handleSelect}
            />
            <Textarea
              _hover={{ borderColor: 'gray.300' }}
              as={TextAreaAutosize}
              borderColor="transparent"
              fontSize="lg"
              fontWeight="bold"
              minH="auto"
              px="2"
              value={entry.title ?? ''}
              onBlur={handleBlur}
              onChange={handleChange}
            />
          </>
        )}
      </Flex>
    );
  }
);
