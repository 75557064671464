import {
  type LaunchOption,
  type LaunchParams,
  toLaunchParams,
} from '@/hooks/useLauncher';
import type { DecoratedQuickCreateEntryJob } from '@/pages/QuickCreate/types';
import { getExperiencePath } from '@/pages/QuickCreate/utils/getExperiencePath';

export const getRedirectLink = (
  isExtension: boolean,
  job: DecoratedQuickCreateEntryJob | null,
  launch: LaunchOption | null = null,
  params: LaunchParams = {}
): string => {
  const path = getExperiencePath(isExtension);

  const query = toLaunchParams(params);
  if (job?.id) {
    query.e = job.id;
  }
  if (launch) {
    query.launch = launch;
  }

  const queryParams = new URLSearchParams(window.location.search);
  new URLSearchParams(query).forEach((value, key) =>
    queryParams.set(key, value)
  );

  const queryString = queryParams.toString();
  const search = queryString.length > 0 ? `?${queryString}` : '';

  return `${path}${search}`;
};
