import { type MutateOptions, useMutation } from '@tanstack/react-query';
import { gql } from 'graphql-request';
import { gqlClient } from '@/lib';
import type { DecoratedQuickCreateEntry } from '@/pages/QuickCreate/types';
import type { CreateEntryInput } from '@/types/gql.generated';
import type { QueryError } from '@/utils/errors';
import type {
  CreateMagicEntryMutation,
  CreateMagicEntryMutationVariables,
} from './useCreateMagicEntry.generated';

type ScheduleLike = {
  id: string;
  timeZone: string;
};

const query = gql`
  mutation CreateMagicEntry($input: CreateEntryInput!) {
    createEntry(input: $input) {
      id
    }
  }
`;

const createInput = (
  schedule: ScheduleLike,
  entry: DecoratedQuickCreateEntry
): CreateEntryInput => {
  const { id, timeZone } = schedule;

  const createRecurrence = (): CreateEntryInput['recurrences'][number] => {
    const startDate = entry.startDate.setZone(timeZone).toUTC().toISO();
    const endDate = entry.endDate.setZone(timeZone).toUTC().toISO();

    return {
      startDate,
      endDate,
      isOnDay: entry.isOnDay,
      rule: entry.rule,
    };
  };

  return {
    scheduleId: id,
    title: entry.title,
    emoji: entry.emoji,
    locationWithPlace: entry.locationWithPlace,
    description: entry.description,
    timeZone: schedule.timeZone,
    recurrences: [createRecurrence()],
  };
};

export const useCreateMagicEntry = () => {
  const { mutate, ...rest } = useMutation<
    CreateMagicEntryMutation,
    QueryError,
    CreateEntryInput
  >({
    mutationFn: (input) => {
      return gqlClient.request<
        CreateMagicEntryMutation,
        CreateMagicEntryMutationVariables
      >(query, { input });
    },
  });

  const createMagicEntry = (
    schedule: ScheduleLike,
    entry: DecoratedQuickCreateEntry,
    options?: MutateOptions<
      CreateMagicEntryMutation,
      QueryError,
      CreateEntryInput
    >
  ) => {
    const input = createInput(schedule, entry);
    return mutate(input, options);
  };

  return {
    ...rest,
    createMagicEntry,
  };
};
