import type { ReactNode } from 'react';
import { PlatformIcon } from '@/components/PlatformIcon';
import type { DecoratedQuickCreateEntry } from '@/pages/QuickCreate/types';
import type { PlatformTypes } from '@/types/gql.generated';
import { Flex, MenuItem } from '@/ui';
import { PlatformPin } from '../../PlatformPin';
import { PlatformPinTooltip } from './PlatformPinTooltip';

type Props = {
  provider: PlatformTypes;
  children: ReactNode;
  onClick: (provider: PlatformTypes) => void;
  hasPlatformTip?: boolean;
  entry?: DecoratedQuickCreateEntry;
};

export const ProviderItem = ({
  provider,
  children,
  hasPlatformTip = false,
  entry,
  onClick,
}: Props) => {
  return (
    <Flex
      _hover={{ bg: 'gray.100' }}
      align="center"
      cursor="pointer"
      data-group
      gap="1"
      justify="space-between"
      pos="relative"
      transitionDuration="var(--chakra-transition-duration-ultra-fast)"
      transitionProperty="var(--chakra-transition-property-background)"
      transitionTimingFunction="var(--chakra-transition-easing-ease-in)"
    >
      <MenuItem
        icon={<PlatformIcon platform={provider} />}
        onClick={() => onClick(provider)}
      >
        {children}
      </MenuItem>
      <PlatformPinTooltip isActive={hasPlatformTip}>
        <PlatformPin
          entry={entry}
          hasPlatformTip={hasPlatformTip}
          platform={provider}
        />
      </PlatformPinTooltip>
    </Flex>
  );
};
