import { Box, Text } from '@/ui';

type Props = {
  hasLocation: boolean;
  isRecurring: boolean;
};

export const PlatformWarning = ({ hasLocation, isRecurring }: Props) => {
  const message =
    "Some calendars don't support copying over {property}.".replace(
      '{property}',
      () => {
        if (isRecurring && hasLocation) {
          return 'recurrence and location';
        } else if (isRecurring) {
          return 'recurrence';
        } else {
          return 'location';
        }
      }
    );

  return (
    <Box
      bg="gray.100"
      borderColor="gray.300"
      borderWidth="1px 0"
      fontSize="sm"
      lineHeight="1.2"
      px="2.5"
      py="2"
    >
      <Text fontWeight="medium">Heads up!</Text>
      {message}
    </Box>
  );
};
