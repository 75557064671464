import { LinkIcon } from '@chakra-ui/icons';
import { AnimatePresence } from 'framer-motion';
import { useEffect, useState } from 'react';
import { useAnalytics } from '@/hooks/useAnalytics';
import {
  MotionBox,
  Box,
  Button,
  Input,
  InputGroup,
  InputLeftElement,
  InputRightElement,
} from '@/ui';

type Props = {
  url: string;
};

export const CopyLinkInput = ({ url }: Props) => {
  const [result, setResult] = useState<'success' | 'error'>();
  const { trackEvent } = useAnalytics();

  const handleCopy = async () => {
    setResult(undefined);
    trackEvent('quick-create:click share', { channel: 'copy' });

    try {
      await navigator.clipboard.writeText(url);
      setResult('success');
    } catch (err) {
      setResult('error');
    }
  };

  useEffect(() => {
    if (result) {
      const timerId = setTimeout(() => {
        setResult(undefined);
      }, 2000);

      return () => clearTimeout(timerId);
    }
  }, [result]);

  return (
    <Box>
      <InputGroup size="md">
        <InputLeftElement pointerEvents="none">
          <LinkIcon />
        </InputLeftElement>
        <Input
          pr="4.5rem"
          readOnly
          value={url}
          onFocus={(event) => event.target.select()}
        />
        <InputRightElement w="4.5rem">
          <Button
            colorScheme="dark"
            fontWeight="bold"
            size="sm"
            onClick={handleCopy}
          >
            Copy
          </Button>
        </InputRightElement>
      </InputGroup>

      <AnimatePresence>
        {result && (
          <MotionBox
            animate={{ opacity: 1, height: 'auto' }}
            color={result === 'success' ? 'green' : 'red'}
            exit={{ opacity: 0, height: 0 }}
            fontSize="sm"
            fontWeight="bold"
            initial={{ opacity: 0, height: 0 }}
            mt="3"
            textAlign="center"
            transition={{ duration: 0.1 }}
          >
            {result === 'success'
              ? 'Link copied to clipboard!'
              : 'Error copying link to clipboard'}
          </MotionBox>
        )}
      </AnimatePresence>
    </Box>
  );
};
