import type { FunctionComponent } from 'react';
import { Flex, type FlexProps, Icon } from '@/ui';

import Calendar from './calendar.svg?react';
import MapPin from './pin.svg?react';

type Field = 'date' | 'location';

type Props = FlexProps & {
  field: Field;
  iconSize?: string;
};

const iconLookup: Record<Field, FunctionComponent> = {
  date: Calendar,
  location: MapPin,
};

export const IconRow = ({
  children,
  field,
  iconSize = '16px',
  ...props
}: Props) => {
  return (
    <Flex align="center" gap="2" {...props}>
      <Flex align="center" boxSize="20px" flexShrink="0" justify="center">
        <Icon
          as={iconLookup[field]}
          boxSize={iconSize}
          pos="relative"
          top="-1px"
        />
      </Flex>

      {children}
    </Flex>
  );
};
