import { useEffect, useState } from 'react';
import { useLauncherAction } from '@/hooks/useLauncher';
import { useIntegrations } from '@/pages/Schedule/hooks/useIntegrations';
import { PlatformTypes } from '@/types/gql.generated';
import { hasGoogleIntegration } from '@/utils/googleCalendar';
import { isPlatformType } from '@/utils/platformLib';
import { useAuthModal } from './store';
import { useHandleAuthenticate } from './useHandleAuthenticate';

export const useHandlePreferredPlatformLaunch = () => {
  const [platformCta, setPlatformCta] = useState<PlatformTypes | null>(null);
  const { handleAuthenticate } = useHandleAuthenticate();
  const { open: openAuth } = useAuthModal();
  const [hasOpenedAuth, setHasOpenedAuth] = useState(false);
  const { data: integrations } = useIntegrations();

  useLauncherAction('set-preferred-platform', ({ platform }) => {
    if (isPlatformType(platform)) {
      handleAuthenticate({ platform });
      setPlatformCta(platform);
    }
  });

  useEffect(() => {
    if (!hasOpenedAuth) {
      if (integrations && !hasGoogleIntegration(integrations)) {
        if (platformCta === PlatformTypes.GoogleLink) {
          setHasOpenedAuth(true);
          openAuth({
            reason: 'set-preferred-platform',
            platform: platformCta,
          });
        }
      }
    }
  }, [hasOpenedAuth, platformCta, integrations, openAuth]);

  return platformCta;
};
