import type { FormEventHandler } from 'react';
import { useAnalytics } from '@/hooks/useAnalytics';
import type { DecoratedQuickCreateEntry } from '@/pages/QuickCreate/types';
import { Button, Grid, InputGroup } from '@/ui';
import { usePlatformExports } from '../../hooks/usePlatformExports';
import { SuccessStat } from '../SendEventButtons/SuccessStat';
import { GoogleCalendarPicker } from './GoogleCalendarPicker';
import { useExportQuickEntries } from './hooks';
import { useCurrentDestination } from './hooks/useCurrentDestination';

type Props = {
  entry: DecoratedQuickCreateEntry;
  onSuccess: () => void;
};

export const GoogleExportForm = ({ entry, onSuccess }: Props) => {
  const { trackEvent } = useAnalytics();
  const { destination, setDestination } = useCurrentDestination();
  const { exportQuickEntries, isPending } = useExportQuickEntries();
  const { platformExports } = usePlatformExports();

  const handleSubmit: FormEventHandler = (event) => {
    event.preventDefault();

    if (!destination) {
      return;
    }

    exportQuickEntries(
      destination,
      [entry],
      {},
      {
        onSuccess: () => onSuccess(),
      }
    );
  };

  const platformExport =
    destination &&
    platformExports.find((item) => item.calendarId === destination.calendar.id);
  const itemExport =
    platformExport &&
    platformExport.entryExports.find((item) => item.entry.id === entry.id);

  return (
    <Grid
      as="form"
      gap="2"
      templateColumns={{ base: '1fr min-content', md: '1fr 125px' }}
      onSubmit={handleSubmit}
    >
      <InputGroup bg="white" minW="0" zIndex="2">
        <GoogleCalendarPicker value={destination} onChange={setDestination} />
      </InputGroup>

      {itemExport ? (
        <SuccessStat
          cta="Go to calendar"
          href={itemExport.externalLink}
          label="Added! 🎉"
        />
      ) : (
        <Button
          isDisabled={!destination}
          isLoading={isPending}
          type="submit"
          onClick={() => {
            trackEvent('quick-create:click send to google-integration');
          }}
        >
          Add
        </Button>
      )}
    </Grid>
  );
};
