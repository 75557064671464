import {
  Card,
  CardBody,
  CardFooter,
} from '@/pages/QuickCreate/components/Card';
import { useQuickCreateContext } from '@/pages/QuickCreate/contexts';
import type { DecoratedQuickCreateEntry } from '@/pages/QuickCreate/types';
import type { RichTextEditorProps } from '@/pages/Schedule/components';
import { forwardRef } from '@/ui';
import { SendEventButtons } from '../SendEventButtons';
import { EntryForm } from './EntryForm';
import { cleanHTML } from './utils';

type Props = {
  entry: DecoratedQuickCreateEntry;
  isFirst: boolean;
};

export const EditEntryCard = forwardRef(({ entry, isFirst }: Props, ref) => {
  const { updateEntry, promptSource } = useQuickCreateContext();

  const handleDescriptionCreate: RichTextEditorProps['onCreate'] = ({
    editor,
  }) => {
    /**
     * Run the prompt that the user selected through TipTap, by way of the
     * description field, to clean and sanitize the prompt's HTML before the
     * it's sent to email or Google Calendar.
     *
     * Further clean the HTML by removing empty paragraph tags. We don't need
     * to do this on the standalone tool prompt box because copying the HTML
     * through Cmd+C appears to do this for us already. The extension uses
     * a cruder mechanism to copy HTML than the browser's native mechanism.
     *
     * This is especially important for Google Calendar where we truncate the
     * description to avoid hitting the browser's URL length limits. Any
     * superflous HTML results in a shorter truncation. This also ensures that
     * the extension produces the same results as the standalone app.
     *
     * Example: the selected text <div style="color: red;">text</div> is
     * transformed into <p>text</p> by TipTap.
     */
    if (promptSource === 'post-message') {
      const html = editor.getHTML();
      const cleanedHTML = cleanHTML(html);
      editor.commands.setContent(cleanedHTML);
      updateEntry(entry.id, { description: cleanedHTML });
    }
  };

  return (
    <Card flexDir="column" p="0" ref={ref}>
      <CardBody>
        <EntryForm
          entry={entry}
          onDescriptionCreate={handleDescriptionCreate}
        />
      </CardBody>
      <CardFooter px="3" py="3">
        <SendEventButtons entry={entry} isFirstItem={isFirst} />
      </CardFooter>
    </Card>
  );
});
