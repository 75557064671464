import { Tooltip, type TooltipProps, forwardRef } from '@/ui';

type Modifier = NonNullable<TooltipProps['modifiers']>[number];

const zIndexModifier: Modifier = {
  name: 'ahCustomStyle',
  enabled: true,
  phase: 'beforeWrite',
  requires: ['computeStyles'],
  fn: ({ state }) => {
    // Chakra doesn't expose the underlying popper wrapper to styling
    // so we use a custom modifier
    state.styles.popper.zIndex = 'calc(var(--chakra-zIndices-overlay) - 1)';
  },
  effect: ({ state }) => {
    if (state.styles.popper) {
      state.styles.popper.zIndex = 'calc(var(--chakra-zIndices-overlay) - 1)';
    }
  },
};

export const MagicTooltip = forwardRef((props: TooltipProps, ref) => {
  return (
    <Tooltip
      sx={{
        a: {
          textDecoration: 'underline',
          color: 'white',
        },
      }}
      {...props}
      bg="purple.600"
      borderRadius="md"
      hasArrow
      modifiers={[zIndexModifier]}
      pl="3"
      placement="top"
      pr="4"
      py="2"
      ref={ref}
    />
  );
});
