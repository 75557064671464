import { DateTime as LuxonDateTime } from 'luxon';
import { IconRow } from '@/pages/QuickCreate/components/IconRow';
import type { DecoratedQuickCreateEntry } from '@/pages/QuickCreate/types';
import { RecurrenceSelect } from '@/pages/Schedule/components/RecurrenceSelect';
import { getDateSummary } from '@/pages/Schedule/utils';
import {
  type FlexProps,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  Text,
  useDisclosure,
} from '@/ui';
import type { QuickEntryChangeHandler } from '../../types';
import { ConvertedFromTime } from './components/ConvertedFromTime';
import { DateRangeInput } from './components/DateRangeInput';
import { DateTimeTriggerButton } from './components/DateTimeTriggerButton';
import { TimeInput } from './components/TimeInput';
import { useClosePopoverOnOutsideClick } from './hooks/useClosePopoverOnOutsideClick';
import { useDateTimeHandlers } from './hooks/useDateTimeHandlers';
import { didConvertTimeZone } from './utils';

type Props = Omit<FlexProps, 'onChange'> & {
  readOnly?: boolean;
  entry: DecoratedQuickCreateEntry;
  onChange: QuickEntryChangeHandler;
};

export const DateTime = ({ entry, readOnly, onChange, ...props }: Props) => {
  const { isOpen, onToggle: toggle, onClose: close } = useDisclosure();
  const { popoverRef, triggerRef } = useClosePopoverOnOutsideClick(close);

  const {
    startDate,
    endDate,
    untilDate,
    isOnDay,
    frequency,
    byWeekday,
    interval,
    rule,
    onStartDateChange,
    onEndDateChange,
    onUntilDateChange,
    onRecurrenceChange,
    onMonthChange,
    onWeekdayChange,
    onAddTime,
    onRemoveTime,
    onStartTimeChange,
    onEndTimeChange,
  } = useDateTimeHandlers(entry, onChange);

  const didConvertZone = didConvertTimeZone(entry);

  let summary = getDateSummary(startDate, endDate, isOnDay, rule);
  if (didConvertZone) {
    summary = `${summary} ${LuxonDateTime.local().toFormat('ZZZZ')}`;
  }

  return (
    <Popover
      isLazy
      isOpen={readOnly ? false : isOpen}
      placement="bottom-start"
      returnFocusOnClose={false}
    >
      <IconRow field="date" {...props}>
        <PopoverTrigger>
          {readOnly ? (
            <Text
              _firstLetter={{ textTransform: 'uppercase' }}
              fontWeight="medium"
              lineHeight="normal"
              px="2"
            >
              {summary}
            </Text>
          ) : (
            <DateTimeTriggerButton
              isOpen={isOpen}
              ref={triggerRef}
              onClick={toggle}
            >
              {summary}
              {didConvertZone && <ConvertedFromTime entry={entry} />}
            </DateTimeTriggerButton>
          )}
        </PopoverTrigger>
      </IconRow>

      <PopoverContent borderRadius="md" ref={popoverRef} w="100%">
        <PopoverBody display="flex" flexDir="column" gap="2" px="4" py="3">
          <RecurrenceSelect
            borderColor="gray.100"
            borderRadius="md"
            byWeekday={byWeekday}
            frequency={frequency}
            interval={interval}
            size="sm"
            startDate={startDate}
            onChange={onRecurrenceChange}
            onMonthChange={onMonthChange}
            onWeekdayChange={onWeekdayChange}
          />
          <DateRangeInput
            endDate={endDate}
            hasRecurrence={frequency !== null}
            isOnDay={isOnDay}
            startDate={startDate}
            untilDate={untilDate}
            onEndChange={onEndDateChange}
            onStartChange={onStartDateChange}
            onUntilChange={onUntilDateChange}
          />
          <TimeInput
            endDate={endDate}
            isOnDay={isOnDay}
            startDate={startDate}
            onAddTimeClick={onAddTime}
            onEndTimeBlur={close}
            onEndTimeChange={onEndTimeChange}
            onRemoveTimeClick={onRemoveTime}
            onStartTimeChange={onStartTimeChange}
          />
        </PopoverBody>
      </PopoverContent>
    </Popover>
  );
};
