import { useEffect, useState } from 'react';
import { Collapse } from '@/components/Collapse';
import { useCurrentUserContext } from '@/contexts';
import { useLauncherAction } from '@/hooks/useLauncher';
import { useToast } from '@/hooks/useToast';
import { usePlatformExports } from '@/pages/QuickCreate/hooks/usePlatformExports';
import { hasMultiExported } from '@/pages/QuickCreate/utils/googleCalendar';
import type { DecoratedIntegration } from '@/pages/Schedule/hooks/useIntegrations';
import { type Calendar, PlatformTypes } from '@/types/gql.generated';
import { Box, CloseButton, Flex, Spinner } from '@/ui';
import { useQuickCreateContext } from '../../../contexts';
import { Card } from '../../Card';
import { LAUNCH_KEY } from '../constants';
import { GoogleCalendarButton } from '../GoogleCalendarButton';
import { GoogleCalendarPicker } from '../GoogleCalendarPicker';
import {
  useCurrentDestination,
  useExportQuickEntries,
  useMultiExportStore,
} from '../hooks';
import { usePickerIntegrations } from '../hooks/usePickerIntegrations';
import type { Destination } from '../types';
import { HeadingText } from './HeadingText';
import { IntegrationErrors } from './IntegrationErrors';
import { MultiExportModal } from './MultiExportModal';
import { ShareButton } from './ShareButton';
import { SocialSharePopover } from './SocialSharePopover';
import { SuccessState } from './SuccessState';
import { useIsStuck } from './useIsStuck';

type Props = {
  /** The text to display underneath the event count heading. Set to `null` to hide */
  byline?: string | null;
};

export const MultiExportCard = ({ byline }: Props) => {
  const { job, page } = useQuickCreateContext();
  const { currentUser } = useCurrentUserContext();
  const {
    integrations,
    isLoading: isLoadingIntegrations,
    unusableGoogleIntegrationCount,
  } = usePickerIntegrations();
  const { destination, setDestination } = useCurrentDestination();
  const toast = useToast();
  const { isStuck, containerRef } = useIsStuck();
  const [modalOpen, setModalOpen] = useState(false);

  const {
    exportQuickEntries,
    isPending: isExporting,
    reset,
  } = useExportQuickEntries();
  const {
    isOpen: isControlOpen,
    open: openControl,
    close: closeControl,
    toggle: toggleControl,
  } = useMultiExportStore();

  const { platformExports } = usePlatformExports();
  const [overrideSuccessState, setOverrideSuccessState] = useState(false);

  const entries = job?.entries ?? [];
  const platformExport = platformExports.find(
    ({ platform }) => platform === destination?.platform
  );
  const hasExportedDestination = Boolean(
    destination && hasMultiExported(destination, platformExports, entries)
  );
  const showSuccessState =
    hasExportedDestination && platformExport && !overrideSuccessState;

  useEffect(() => {
    if (hasExportedDestination) {
      openControl();
    }
  }, [hasExportedDestination, openControl]);

  useEffect(() => {
    if (!currentUser) {
      closeControl();
    }
  }, [currentUser, closeControl]);

  useLauncherAction(LAUNCH_KEY, () => {
    setModalOpen(true);
    openControl();
  });

  const addToGoogleButtonText =
    entries.length > 1
      ? `Add all ${entries.length} to Google`
      : 'Add to Google';

  const handleAdd = (destination: Destination) => {
    exportQuickEntries(
      destination,
      entries,
      { ignorePreviousExports: true },
      {
        onSuccess: () => setOverrideSuccessState(false),
        onError: () => {
          toast.error(
            'Whoops! We had some trouble sharing to your calendar. Please try again.'
          );
        },
      }
    );
  };

  const handleClose = () => {
    reset();
    setOverrideSuccessState(true);
    closeControl();
  };

  const handleReset = () => {
    reset();
    setOverrideSuccessState(true);
  };

  const handleModalSelect = (
    integration: DecoratedIntegration,
    calendar: Calendar
  ) => {
    const destination: Destination = {
      calendar,
      integration,
      platform: PlatformTypes.GoogleIntegration,
    };
    setDestination(destination);
    handleAdd(destination);
    setModalOpen(false);
  };

  return (
    <>
      <MultiExportModal
        isOpen={modalOpen}
        onClose={() => setModalOpen(false)}
        onSelect={handleModalSelect}
      />

      <Box
        mt="-4"
        pos="sticky"
        ref={containerRef}
        top="-1px" // https://css-tricks.com/how-to-detect-when-a-sticky-element-gets-pinned/
        zIndex="1"
      >
        {/* This div sits underneath the card to prevent entry cards from bleeding underneath */}
        <Box
          bg={isStuck ? 'linear-gradient(#dbd2ee, #dcd3ef)' : 'transparent'}
          h="calc(var(--chakra-space-6) + 1px)" // account for the -1px adjustment above
          mb="-2"
        />

        <Card
          bg="#FAF5FA"
          direction="column"
          pt="4"
          px={{ base: 4, sm: 6 }}
          py="4"
          shadow="0px 0px 12px 0px rgba(152, 130, 206, 0.50)"
        >
          <Flex
            direction={{ base: 'column', sm: 'row' }}
            justify="space-between"
          >
            <Flex align="center" justify="space-between" minH="10">
              <HeadingText byline={byline} numEntries={entries.length} />
              <CloseButton
                display={{ base: isControlOpen ? 'block' : 'none', sm: 'none' }}
                onClick={handleClose}
              />
            </Flex>

            <Flex
              align="center"
              direction={{ base: 'row-reverse', sm: 'row' }}
              display={{ base: isControlOpen ? 'none' : 'flex', sm: 'flex' }}
              gap="2"
              justify={{ base: 'flex-end', sm: 'flex-start' }}
              mt={{ base: 2, sm: 0 }}
            >
              {isControlOpen ? (
                <CloseButton onClick={handleClose} />
              ) : page?.socialSharingEnabled ? (
                <SocialSharePopover />
              ) : (
                <ShareButton />
              )}

              {!isControlOpen && (
                <>
                  {integrations.length > 0 ? (
                    <GoogleCalendarButton onClick={toggleControl}>
                      {addToGoogleButtonText}
                    </GoogleCalendarButton>
                  ) : (
                    <GoogleCalendarButton
                      isAuth
                      isLoading={isLoadingIntegrations}
                      job={job}
                      launch={LAUNCH_KEY}
                    >
                      {addToGoogleButtonText}
                    </GoogleCalendarButton>
                  )}
                </>
              )}
            </Flex>
          </Flex>

          <Collapse
            display="flex"
            flexDirection="column"
            gap="3"
            in={isControlOpen}
            mt="2"
            overflow="hidden"
          >
            {!isLoadingIntegrations && (
              <IntegrationErrors onReportClick={toggleControl} />
            )}

            {isLoadingIntegrations ? (
              <Flex align="center" flex="1" gap="2">
                <Spinner size="sm" />
                Loading calendars
              </Flex>
            ) : showSuccessState ? (
              <SuccessState
                platformExport={platformExport}
                onResetClick={handleReset}
              />
            ) : integrations.length > 0 ? (
              <Flex align="center" flex="1" gap="2">
                <GoogleCalendarPicker
                  value={destination}
                  onChange={setDestination}
                />
                <GoogleCalendarButton
                  flexShrink="0"
                  isDisabled={!destination}
                  isLoading={isExporting}
                  onClick={() => destination && handleAdd(destination)}
                >
                  Add
                </GoogleCalendarButton>
              </Flex>
            ) : null}
          </Collapse>

          {!isControlOpen &&
            integrations.length === 0 &&
            !isLoadingIntegrations &&
            unusableGoogleIntegrationCount > 0 && (
              <IntegrationErrors mt="3" onReportClick={toggleControl} />
            )}
        </Card>
      </Box>
    </>
  );
};
