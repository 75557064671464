import { AuthPanel } from '@/components/auth';
import type { LaunchOption } from '@/hooks/useLauncher';
import { useQuickCreateContext } from '@/pages/QuickCreate/contexts';
import { PlatformTypes } from '@/types/gql.generated';
import { Heading, Text } from '@/ui';
import { useIsExtension } from '../../hooks/useIsExtension';
import { getRedirectLink } from './authLib';
import { HighlightPlatform } from './HighlightPlatform';
import type { LaunchReason } from './types';
import { useHandleAuthenticate } from './useHandleAuthenticate';

type Props = {
  reason: LaunchReason | null;
  platform: PlatformTypes | null;
  launch?: LaunchOption;
  onComplete: () => void;
};

type HeroProps = Pick<Props, 'platform' | 'reason'>;

const HeroText = ({ reason, platform }: HeroProps) => {
  if (reason === 'add-to-calendar' && platform) {
    const text =
      platform === PlatformTypes.Email
        ? 'Log in to sign up to email this event'
        : 'Log in or sign up to add this event to {platform}';

    return <HighlightPlatform platform={platform} text={text} />;
  }

  if (reason === 'set-preferred-platform' && platform) {
    return (
      <HighlightPlatform
        platform={platform}
        text="Log in or sign up to pin {platform} as your calendar destination"
      />
    );
  }

  if (reason === 'create-share-link') {
    return 'Log in or sign up to create a shareable link';
  }

  if (reason === 'google-calendar-integration-multi') {
    return 'Connect your Google Calendar to add events';
  }

  return 'Log in or create an account';
};

export const AuthBody = ({ reason, platform, onComplete, launch }: Props) => {
  const { job } = useQuickCreateContext();
  const { isExtension } = useIsExtension();
  const { handleAuthenticate } = useHandleAuthenticate();

  const redirectTo =
    platform && reason === 'set-preferred-platform'
      ? getRedirectLink(isExtension, job, 'set-preferred-platform', {
          platform,
        })
      : getRedirectLink(isExtension, job, launch || 'magic-auth-success', {
          reason: reason ?? 'unknown',
        });
  const isGooglePlatform = platform
    ? [PlatformTypes.GoogleIntegration, PlatformTypes.GoogleLink].includes(
        platform
      )
    : false;

  return (
    <>
      <Text fontSize="72px" mb="6" textAlign="center">
        ✨
      </Text>

      <AuthPanel
        fullWidth
        hideLogo
        redirectTo={redirectTo}
        googleOptions={{
          authorizeCalendar:
            reason === 'google-calendar-integration-multi' ||
            (reason === 'add-to-calendar' && isGooglePlatform),
          googleOnly: reason === 'google-calendar-integration-multi',
          googleFirst: isGooglePlatform,
        }}
        renderHeader={() => (
          <Heading
            as="h2"
            fontSize={{ base: '2xl', sm: '4xl' }}
            fontWeight="bold"
            mb="7"
            textAlign="center"
          >
            <HeroText platform={platform} reason={reason} />
          </Heading>
        )}
        onAuthenticated={() => {
          handleAuthenticate({ platform: platform || undefined, onComplete });
        }}
      />
    </>
  );
};
