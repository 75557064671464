import { useRef } from 'react';
import { useAnalytics } from '@/hooks/useAnalytics';
import { useQuickCreateContext } from '@/pages/QuickCreate/contexts';
import type { DecoratedQuickCreateEntry } from '@/pages/QuickCreate/types';
import type { RichTextEditorProps } from '@/pages/Schedule/components';
import { Flex, type FlexProps } from '@/ui';
import { DateTime } from './components/DateTime';
import { Description } from './components/Description';
import { Location } from './components/Location';
import { Title } from './components/Title';

type Props = FlexProps & {
  entry: DecoratedQuickCreateEntry;
  onDescriptionCreate?: RichTextEditorProps['onCreate'];
};

type FieldName = 'emoji' | 'title' | 'datetime' | 'location' | 'description';

export const EntryForm = ({ entry, onDescriptionCreate, ...props }: Props) => {
  const { updateEntry, readOnly } = useQuickCreateContext();
  const { trackEvent } = useAnalytics();
  const { current: editedFields } = useRef<Set<FieldName>>(new Set());

  const trackEdit = (field: FieldName): void => {
    if (!editedFields.has(field)) {
      trackEvent('quick-create:make edit', { field });
      editedFields.add(field);
    }
  };

  if (!entry) {
    return;
  }

  const showLocationField =
    !readOnly || (readOnly && !!entry.locationWithPlace?.name);

  return (
    <Flex
      direction="column"
      flex="1"
      gap={readOnly ? 2 : 0}
      w="100%"
      {...props}
    >
      <Title
        entry={entry}
        mb="2"
        readOnly={readOnly}
        onChange={(changes) => {
          updateEntry(entry.id, changes);
          trackEdit('title' in changes ? 'title' : 'emoji');
        }}
      />
      <DateTime
        entry={entry}
        readOnly={readOnly}
        onChange={(changes) => {
          updateEntry(entry.id, changes);
          trackEdit('datetime');
        }}
      />
      {showLocationField && (
        <Location
          entry={entry}
          readOnly={readOnly}
          onChange={(changes) => {
            updateEntry(entry.id, changes);
            trackEdit('location');
          }}
        />
      )}
      {(!readOnly || (readOnly && entry.description)) && (
        <Description
          entry={entry}
          ml="-2"
          mt={readOnly ? 0 : 1}
          readOnly={readOnly}
          onCreate={onDescriptionCreate}
          onChange={(changes) => {
            updateEntry(entry.id, changes);
            trackEdit('description');
          }}
        />
      )}
    </Flex>
  );
};
